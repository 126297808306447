import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { orderBy } from 'lodash'

import { notification } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { TYPE_CONTRACTOR } from 'common/data-access/company_websocket'
import { OrderChannelMessage, subscribeOrderChannel } from 'common/data-access/order_websocket'
import { calcExtCost } from 'common/helpers/order'
import { useQuery } from 'common/hooks/use-query'
import { WebSocketMessage } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import {
  OrderSessionOwner,
  release_session,
  unlock_session,
  UnlockOrderSessionBody,
  update_session,
  UpdateOrderSessionBody,
  UpdateOrderSessionResponse,
} from 'contractor/server/order_sessions'
import { ShowOrderResponse } from 'contractor/server/orders'

import { makeTableData, TableDataSource } from './make_table_data'

const LevelingContext = createContext<LevelingContextrops>({} as LevelingContextrops)

export const useLeveling = () => useContext(LevelingContext)

type ChangeQuantityParams = { quantity: number; rowIndex: number; vendorIndex: number }

interface GetSubtotalAvgAndMinMaxSavedAmount {
  (): {
    subtotalSavedAmount: number
    subtotalMinMaxSavedAmount: number
    avgMaterialSavingsBreakdown: {
      companyMaterialId: string
      quantity: number
      savings: number
    }[]
    minMaxMaterialSavingsBreakdown: {
      companyMaterialId: string
      quantity: number
      savings: number
    }[]
  }
}

interface OrderSession {
  externalUsers: OrderSessionOwner[]
  isBlocked: boolean
  isReleased: boolean
  modalOrderExpiredOpened: boolean
  modalOrderLockedOpened: boolean
  modalOrderUnlockedOpened: boolean
  modalOrderReleasedOpened: boolean
  onCloseModalOrderLocked: () => void
  onCloseModalOrderUnlocked: () => void
  onOpenModalOrderUnlocked: () => void
  onRenew: () => void
  onUnlock: () => void
  onUpdate: () => void
  unlockEnabled?: boolean
}

type LevelingContextrops = {
  isSplitting: boolean
  toggleSplitting: React.Dispatch<React.SetStateAction<boolean>>
  tableData: TableDataSource[]
  orders: ShowOrderResponse[]
  handleChangeQuantity: (params: ChangeQuantityParams) => void
  handleCancelSplitting: () => void
  handleStartSplitting: () => void
  getOriginalAvgSubTotal: () => number
  getOriginalAvgGrandTotal: () => number
  getSubtotalAvgAndMinMaxSavedAmount: GetSubtotalAvgAndMinMaxSavedAmount
  getGrandTotalSavedAmount: () => number
  getGrandTotalMinMaxSavedAmount: () => number
  handleMakeTableData: () => void
  handleSetPlaceOrder: (orderId: string) => void
  printAreaRef: React.MutableRefObject<HTMLTableElement>
  ordersSession: OrderSession
  goBack: (isRefresh?: boolean) => void
}

export const LevelingProvider = observer(({ children }) => {
  const featureFlagOrderBlockingEnabled = useFlag('order_blocking')
  const { orderPackageStore, companySettingStore, integrationStore, userStore } = useStores()

  const params = useParams()
  const history = useHistory()
  const lastLocation = useLastLocation()

  const { data, isLoading, refetch } = useQuery(
    () => orderPackageStore.getOrderPackage(params['id'], { with_discarded: true }),
    [params['id']],
  )

  useQuery(companySettingStore.indexCompanyMaterialConfiguration)
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useEffect(() => {
    return () => {
      orderPackageStore.orderPackage = null
    }
  }, [params])

  const printAreaRef = useRef(null)

  const [isSplitting, toggleSplitting] = useState(false)

  const [tableData, setTableData] = useState<TableDataSource[]>([])
  const [orders, setOrders] = useState<ShowOrderResponse[]>([])
  const [ordersSessionOwners, setOrdersSessionOwners] = useState<OrderSessionOwner[]>([])
  const webSocketSubscriptions = new Map()

  const [showOrderLockedState, setShowOrderLocked] = useState(false)
  const [showOrderUnlockedState, setShowOrderUnlockedState] = useState(false)

  const userIsImpersonating = userStore.currentUser?.impersonating
  const orderIds = (orders || [])?.map((order) => order.id)
  const orderBlockingEnabled = featureFlagOrderBlockingEnabled && !!orderIds.length
  const userHasUnlockEnabled = userStore.canUnlockOrder

  const goBack = (isRefresh = false) => {
    if (lastLocation) {
      const search = new URLSearchParams(lastLocation?.search)
      history.push({
        ...lastLocation,
        search: `?${search.toString()}`,
      })
    } else {
      history.push('/orders', {
        search: isRefresh && '?refresh=true',
      })
    }
  }

  const updateOrderSession = async (payload: UpdateOrderSessionBody, user: OrderSessionOwner): Promise<void> => {
    try {
      await update_session(payload)
      setOrdersSessionOwners(
        orders.map((order) => ({
          orderId: order.id,
          ...user,
        })),
      )
    } catch (error) {
      const sessionInUseError = error.response?.data.errors.find(
        (error) => error.name === 'sessions_in_use_by_another_user',
      )

      if (sessionInUseError) {
        setOrdersSessionOwners(
          orders.map((order) => {
            const owner = sessionInUseError.data.find((data) => data.order === order.id)
            if (owner) {
              return { orderId: order.id, ...owner.user }
            }
            return { orderId: order.id, id: null, name: null }
          }),
        )
      }
    }
  }

  const unlockOrderSession = async (payload: UnlockOrderSessionBody, user: OrderSessionOwner): Promise<void> => {
    await unlock_session(payload)
    setOrdersSessionOwners(
      orders.map((order) => ({
        orderId: order.id,
        ...user,
      })),
    )
  }

  const { data: ordersSessionsData, isLoading: ordersSessionsIsLoading } = useQuery<UpdateOrderSessionResponse>(
    async () => {
      const name = `${userStore.currentUser.first_name} ${userStore.currentUser.last_name}`
      const user = { id: userStore.currentUser.id, name }
      return updateOrderSession({ order_ids: orderIds }, user)
    },
    [orderIds.length],
    !!orderIds.length,
  )

  const ordersSession = useMemo(() => {
    if (!orderIds.length) {
      return {
        externalUsers: [],
        isBlocked: false,
        isReleased: false,
        modalOrderExpiredOpened: false,
        modalOrderLockedOpened: false,
        modalOrderUnlockedOpened: false,
        modalOrderReleasedOpened: false,
        onCloseModalOrderLocked: () => null,
        onCloseModalOrderUnlocked: () => null,
        onOpenModalOrderUnlocked: () => null,
        onRenew: () => null,
        onUnlock: () => null,
        onUpdate: () => null,
        unlockEnabled: false,
      }
    }

    const sessionIsMine = !!ordersSessionOwners.find((user) => user.id === userStore.currentUser.id)
    const externalUsers = ordersSessionOwners.filter((user) => user.id && user.id !== userStore.currentUser.id)
    const isBlocked = orderBlockingEnabled && !!externalUsers.length && !ordersSessionsIsLoading
    const isReleased = !sessionIsMine && !ordersSessionsIsLoading && !externalUsers.length
    const modalOrderExpiredOpened = orderBlockingEnabled && !!ordersSessionOwners.find((user) => user.expired)
    const modalOrderLockedOpened = orderBlockingEnabled && showOrderLockedState && !!externalUsers.length
    const modalOrderUnlockedOpened = orderBlockingEnabled && showOrderUnlockedState && userHasUnlockEnabled
    const modalOrderReleasedOpened = orderBlockingEnabled && isReleased && !userIsImpersonating
    const unlockEnabled = orderBlockingEnabled && userHasUnlockEnabled

    const onCloseModalOrderLocked = () => setShowOrderLocked(false)

    const onOpenModalOrderUnlocked = () => {
      onCloseModalOrderLocked()
      setShowOrderUnlockedState(true)
    }
    const onCloseModalOrderUnlocked = () => setShowOrderUnlockedState(false)

    const onRenew = () => {
      if (orderBlockingEnabled) {
        updateOrderSession(
          { order_ids: orderIds },
          {
            id: userStore.currentUser.id,
            name: userStore.currentUser.first_name,
          },
        )
      }
    }

    const onUpdate = () => {
      if (orderBlockingEnabled) refetch()
      updateOrderSession(
        { order_ids: orderIds },
        {
          id: userStore.currentUser.id,
          name: userStore.currentUser.first_name,
        },
      )
    }

    const onUnlock = async () => {
      onCloseModalOrderUnlocked()
      try {
        unlockOrderSession(
          { order_ids: orderIds },
          {
            id: userStore.currentUser.id,
            name: userStore.currentUser.first_name,
          },
        )
      } catch (e) {
        notification.error({
          message: 'Error while unlock rfq, try again later',
          placement: 'bottomLeft',
        })
      }
    }

    return {
      externalUsers,
      isBlocked,
      isReleased,
      modalOrderExpiredOpened,
      modalOrderLockedOpened,
      modalOrderReleasedOpened,
      modalOrderUnlockedOpened,
      onCloseModalOrderLocked,
      onCloseModalOrderUnlocked,
      onOpenModalOrderUnlocked,
      onRenew,
      onUnlock,
      onUpdate,
      unlockEnabled,
    }
  }, [
    ordersSessionsIsLoading,
    orderIds,
    orderBlockingEnabled,
    ordersSessionOwners,
    userStore.currentUser.id,
    userStore.currentUser.first_name,
    showOrderLockedState,
    showOrderUnlockedState,
    userHasUnlockEnabled,
    userIsImpersonating,
  ])

  const handleWebSocketOrderSessionMessage = () => {
    return (message: WebSocketMessage<OrderChannelMessage>) => {
      const orderIdReceived = message.entity_id
      if (message.data?.order_will_expire) {
        setOrdersSessionOwners((prevOrdersSessionOwners) =>
          prevOrdersSessionOwners?.map((orderSessionOwner) => {
            if (orderSessionOwner.orderId === orderIdReceived) {
              return { ...orderSessionOwner, expired: orderSessionOwner.id === userStore.currentUser?.id }
            }
            return orderSessionOwner
          }),
        )
      }
      if (message.data?.order_released) {
        setOrdersSessionOwners((prevOrdersSessionOwners) =>
          prevOrdersSessionOwners?.map((orderSessionOwner) => {
            if (orderSessionOwner.orderId === orderIdReceived) {
              return { orderId: orderSessionOwner.orderId, id: null, name: null }
            }
            return orderSessionOwner
          }),
        )
      }
      if (message.data?.order_blocked) {
        setOrdersSessionOwners((prevOrdersSessionOwners) =>
          prevOrdersSessionOwners?.map((orderSessionOwner) => {
            if (orderSessionOwner.orderId === orderIdReceived) {
              return { orderId: orderSessionOwner.orderId, ...message.data.user }
            }
            return orderSessionOwner
          }),
        )
      }
      if (message.data?.order_blocked_manually && message.data.user?.id !== userStore.currentUser?.id) {
        setOrdersSessionOwners((prevOrdersSessionOwners) =>
          prevOrdersSessionOwners?.map((orderSessionOwner) => {
            if (orderSessionOwner.orderId === orderIdReceived) {
              return { orderId: orderSessionOwner.orderId, ...message.data.user, manually_blocked: true }
            }
            return orderSessionOwner
          }),
        )
        release_session({ order_ids: orderIds.filter((orderId) => orderId !== orderIdReceived) })
      }
    }
  }

  const orderSessionSubscribe = (ids: string[]) => {
    ids.forEach((id) => {
      const subscription = subscribeOrderChannel(handleWebSocketOrderSessionMessage(), TYPE_CONTRACTOR, id)
      webSocketSubscriptions.set(id, subscription)
    })
  }

  const orderSessionUnsubscribe = (ids: string[]) => {
    ids.forEach((id) => {
      const subscription = webSocketSubscriptions.get(id)
      if (subscription) {
        subscription.unsubscribe()
        webSocketSubscriptions.delete(id)
      }
    })
  }

  const handleMakeTableData = useCallback(() => {
    const orderPackage = toJS(orderPackageStore.orderPackage)
    const sortedOrders = orderBy(orderPackage?.orders, 'company_vendor.vendor_name')

    setOrders(sortedOrders)
    setTableData(makeTableData(sortedOrders))
  }, [orderPackageStore.orderPackage?.id, orderPackageStore.orderPackage?.updated_at])

  const handleChangeQuantity = ({ quantity, rowIndex, vendorIndex }: ChangeQuantityParams) => {
    setTableData((prevTableData) => {
      const newTableData = [...prevTableData]
      newTableData[rowIndex].vendor_items[vendorIndex].quantity = quantity

      const extendedCost = calcExtCost({
        quantity: quantity,
        unitCost: newTableData[rowIndex].vendor_items[vendorIndex].unit_cost,
        multiplier: newTableData[rowIndex].company_material?.unit?.multiplier as unknown as number,
        qtyIncrement: newTableData[rowIndex].company_material?.unit?.qty_increment as unknown as number,
      })

      newTableData[rowIndex].vendor_items[vendorIndex].extended_cost = extendedCost

      return newTableData
    })
  }

  const handleCancelSplitting = () => {
    toggleSplitting(false)
    handleMakeTableData()
  }

  const handleStartSplitting = () => {
    toggleSplitting(true)
    setTableData((prevTableData) =>
      prevTableData.map((data) => ({
        ...data,
        vendor_items: data.vendor_items.map((vendorItem) => ({
          ...vendorItem,
          quantity: vendorItem.quantity || 0,
          extended_cost: vendorItem.isLowestPrice ? vendorItem.extended_cost : 0,
        })),
      })),
    )
  }

  // When the user places one order directly sets the materials from this order with the quantity to make the calculations using the selected one
  const handleSetPlaceOrder = (orderId) => {
    setTableData((prevTableData) =>
      prevTableData.map((data) => ({
        ...data,
        vendor_items: data.vendor_items.map((vendorItem) => {
          if (vendorItem.orderId === orderId) {
            return {
              ...vendorItem,
              quantity: data.quantity,
            }
          }

          return {
            ...vendorItem,
            quantity: null,
          }
        }),
      })),
    )
  }

  const getDeliveriesCosts = () => {
    const { discountValue, shippingValue, otherValue, taxValue } = orders.reduce(
      (acc, order) => {
        const deliveriesCosts = order?.deliveries.reduce(
          (acc, delivery) => {
            acc.discountValue += Number(delivery.discount_value)
            acc.shippingValue += Number(delivery.shipping_value)
            acc.otherValue += Number(delivery.other_value)
            acc.taxValue += Number(delivery.tax_value)
            return acc
          },
          {
            discountValue: 0,
            shippingValue: 0,
            otherValue: 0,
            taxValue: 0,
          },
        )

        return {
          discountValue: acc.discountValue + deliveriesCosts.discountValue,
          shippingValue: acc.shippingValue + deliveriesCosts.shippingValue,
          otherValue: acc.otherValue + deliveriesCosts.otherValue,
          taxValue: acc.taxValue + deliveriesCosts.taxValue,
        }
      },
      {
        discountValue: 0,
        shippingValue: 0,
        otherValue: 0,
        taxValue: 0,
      },
    )

    /*
      Get the average only for the orders that have delivery costs informed.
      In the same way that we calculate the order materials' average costs, excluding non-informed values.
    */
    const orderWithDiscountFilled = orders.filter((order) =>
      order.deliveries.some((delivery) => !!delivery?.discount_value),
    ).length
    const orderWithShippingFilled = orders.filter((order) =>
      order.deliveries.some((delivery) => !!delivery?.shipping_value),
    ).length
    const orderWithOtherFilled = orders.filter((order) =>
      order.deliveries.some((delivery) => !!delivery?.other_value),
    ).length
    const orderWithTaxFilled = orders.filter((order) =>
      order.deliveries.some((delivery) => !!delivery?.tax_value),
    ).length

    const avgDiscountValue = orderWithDiscountFilled ? discountValue / orderWithDiscountFilled : 0
    const avgShippingValue = orderWithShippingFilled ? shippingValue / orderWithShippingFilled : 0
    const avgOtherValue = orderWithOtherFilled ? otherValue / orderWithOtherFilled : 0
    const avgTaxValue = orderWithTaxFilled ? taxValue / orderWithTaxFilled : 0

    return avgTaxValue + avgOtherValue + avgShippingValue - avgDiscountValue
  }

  const getOriginalAvgSubTotal = () => {
    const originalAvgSubTotal = tableData.reduce((acc, tableRow) => {
      /*
        Get the average only for the material that has unit cost informed.
        Sometimes occur that a vendor doesn't' fill in the unit cost and we exclude that one to calc the average, to be more accurate on the costs.
      */
      const vendorItemsWithPrices = tableRow.vendor_items.filter((vendorItem) => !!vendorItem?.originalUnitCost)
      const rowSumPrice = vendorItemsWithPrices.reduce((acc, vendorItem) => {
        const extendedCost = calcExtCost({
          quantity: vendorItem?.originalQuantity,
          unitCost: vendorItem?.originalUnitCost,
          multiplier: tableRow.company_material?.unit?.multiplier as unknown as number,
          qtyIncrement: tableRow.company_material?.unit?.qty_increment as unknown as number,
        })

        return acc + extendedCost
      }, 0)

      const quantityPricesFilled = vendorItemsWithPrices.length

      if (rowSumPrice === 0 || quantityPricesFilled === 0) {
        return acc
      }

      const rowAvgPrice = rowSumPrice / quantityPricesFilled

      return acc + rowAvgPrice
    }, 0)

    return originalAvgSubTotal
  }

  const getOriginalAvgGrandTotal = () => {
    const deliveriesCosts = getDeliveriesCosts()
    const originalAvgSubTotal = getOriginalAvgSubTotal()
    return originalAvgSubTotal + deliveriesCosts
  }

  /*
    Calculate the saved amount by each material and return the total saved amount.
    - To calculate the original average price we sum all the materials prices and divide by the number of vendors that informed the price.
    - To calculate the ordered average we sum all the ordered materials prices (it means the materials that have quantity),
      and divide by the number of vendors who have quantity. 
      Then originalRowAvgPrice - orderedRowAvgPrice will give the saved price by material, now we sum all materials and get the total saved amount.
  */
  const getSubtotalAvgAndMinMaxSavedAmount = () => {
    const savingsByCompanyMaterial = tableData.map((tableRow) => {
      const vendorItemsWithPrices = tableRow.vendor_items.filter((vendorItem) => !!vendorItem?.originalExtendedCost)
      const originalExtendedCosts = vendorItemsWithPrices.map((vendorItem) => vendorItem.originalExtendedCost)

      const originalRowSumPrice = originalExtendedCosts.reduce((acc, originalExtendedCost) => {
        return acc + originalExtendedCost
      }, 0)
      const originalRowAvgPrice = vendorItemsWithPrices?.length
        ? originalRowSumPrice / vendorItemsWithPrices?.length
        : 0

      const vendorItemsWithQuantity = tableRow.vendor_items.filter((vendorItem) => !!vendorItem?.quantity)
      const extendedCosts = vendorItemsWithQuantity.map((vendorItem) => vendorItem.extended_cost)
      const orderedRowSumPrice = extendedCosts.reduce((acc, extendedCost) => {
        return acc + extendedCost
      }, 0)
      const orderedRowAvgPrice = vendorItemsWithQuantity.length
        ? orderedRowSumPrice / vendorItemsWithQuantity.length
        : 0

      const savedAmount = originalRowAvgPrice - orderedRowAvgPrice
      const minMaxSavedAmount = originalExtendedCosts.length
        ? Math.max(...originalExtendedCosts) - orderedRowAvgPrice
        : 0

      return {
        savedAmount,
        minMaxSavedAmount,
        companyMaterialId: tableRow.company_material.id,
        quantity: tableRow.quantity,
      }
    }, 0)

    const subtotalSavedAmount = savingsByCompanyMaterial.reduce(
      (acc, savingByCompanyMaterial) => acc + savingByCompanyMaterial.savedAmount,
      0,
    )
    const subtotalMinMaxSavedAmount = savingsByCompanyMaterial.reduce(
      (acc, savingByCompanyMaterial) => acc + savingByCompanyMaterial.minMaxSavedAmount,
      0,
    )

    return {
      subtotalSavedAmount,
      subtotalMinMaxSavedAmount,
      avgMaterialSavingsBreakdown: savingsByCompanyMaterial.map((savingByCompanyMaterial) => ({
        companyMaterialId: savingByCompanyMaterial.companyMaterialId,
        quantity: savingByCompanyMaterial.quantity,
        savings: savingByCompanyMaterial.savedAmount,
      })),
      minMaxMaterialSavingsBreakdown: savingsByCompanyMaterial.map((savingByCompanyMaterial) => ({
        companyMaterialId: savingByCompanyMaterial.companyMaterialId,
        quantity: savingByCompanyMaterial.quantity,
        savings: savingByCompanyMaterial.minMaxSavedAmount,
      })),
    }
  }

  const getGrandTotalSavedAmount = () => {
    const deliveriesCosts = getDeliveriesCosts()
    const subtotalSavedAmount = getSubtotalAvgAndMinMaxSavedAmount().subtotalSavedAmount
    return subtotalSavedAmount + deliveriesCosts
  }

  const getGrandTotalMinMaxSavedAmount = () => {
    const deliveriesCosts = getDeliveriesCosts()
    const subtotalMinMaxSavedAmount = getSubtotalAvgAndMinMaxSavedAmount().subtotalMinMaxSavedAmount
    return subtotalMinMaxSavedAmount + deliveriesCosts
  }

  useEffect(() => {
    if (data) handleMakeTableData()
  }, [data, handleMakeTableData])

  useEffect(() => {
    if (orderBlockingEnabled) {
      const isExternalUser = ordersSessionsData?.ownerUser?.id !== userStore.currentUser.id
      if (isExternalUser) {
        setShowOrderLocked(true)
      }
    }
  }, [ordersSessionsData, orderBlockingEnabled])

  useEffect(() => {
    if (orderBlockingEnabled) {
      ordersSessionOwners.forEach((orderSessionOwner) => {
        if (orderSessionOwner.manually_blocked) {
          setShowOrderLocked(true)
          return
        }
      })
    }
  }, [ordersSessionOwners])

  useEffect(() => {
    if (!orderIds.length) return

    orderSessionSubscribe(orderIds)
    return () => {
      orderSessionUnsubscribe(orderIds)
    }
  }, [orderIds.length])

  useEffect(() => {
    /*
      If the feature is disabled, the process of entering the session becomes automatic,
      so whoever calls the request first is saved in it. If it is enabled, the process
      involves clicking on the modal to access the session.
    */
    if (!featureFlagOrderBlockingEnabled && ordersSession?.isReleased) {
      ordersSession?.onUpdate()
    }
  }, [ordersSession?.isReleased])

  if (isLoading && !data) {
    return <Loading />
  }

  if (!orderPackageStore.orderPackage) {
    return null
  }

  return (
    <LevelingContext.Provider
      value={{
        isSplitting,
        toggleSplitting,
        tableData,
        orders,
        handleChangeQuantity,
        handleCancelSplitting,
        handleStartSplitting,
        getOriginalAvgSubTotal,
        getOriginalAvgGrandTotal,
        getSubtotalAvgAndMinMaxSavedAmount,
        getGrandTotalSavedAmount,
        getGrandTotalMinMaxSavedAmount,
        handleMakeTableData,
        handleSetPlaceOrder,
        printAreaRef,
        ordersSession,
        goBack,
      }}
    >
      {children}
    </LevelingContext.Provider>
  )
})

export const withLevelingProvider = (Component) => (props) => {
  return (
    <LevelingProvider>
      <Component {...props} />
    </LevelingProvider>
  )
}
