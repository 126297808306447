import React from 'react'

import { useLocation } from 'react-router-dom'

import { Select, SelectProps } from 'antd'

import { trackTableFiltering } from 'common/analytics/event_tracking'
import { formatEnumValue } from 'common/helpers/formatters'

interface Item {
  label: string
  value: string
}

interface SelectFilterProps {
  items: Item[]
  selected: Item[] | string
  onChange: (item: Item[]) => void
  placeholder: string
  attribute: string
  isSearchable?: boolean
  formatter?: (string) => string
  sortItems?: (string) => string[]
  dropdownRender?: SelectProps['dropdownRender']
}

const SelectFilter = ({
  items,
  selected,
  onChange,
  placeholder,
  attribute,
  formatter = formatEnumValue,
  dropdownRender,
}: SelectFilterProps) => {
  const location = useLocation()
  const source = location.pathname.substr(1)

  return (
    <Select
      mode="multiple"
      allowClear
      aria-label={placeholder.toLowerCase().replace(/\s+/g, '-')}
      placeholder={placeholder}
      onChange={(value = []) => {
        // @todo: we should handle this in a better way -> onChange(value as Item[])
        onChange(value as Item[])
        trackTableFiltering(source, attribute)
      }}
      style={{ width: '100%' }}
      value={selected}
      optionLabelProp="label"
      showArrow
      dropdownRender={dropdownRender}
    >
      {items.map((item) => (
        <Select.Option key={item.label} value={item.value} label={formatter(item.label)} style={{ minHeight: 35 }}>
          {formatter(item.label)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectFilter
