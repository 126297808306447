import { ENVIRONMENT } from 'common/helpers/check_environment'

export interface HeapUser {
  id: string
  email: string
  role: string
  type: 'CONTRACTOR' | 'VENDOR'
  account: {
    id: string
    name: string
    type?: string
  }
}

// Ignore initialization when is development
// The event tracking is raising errors while e2e is executing
let heapInitialized = ENVIRONMENT === 'development'

export const initializeHeap = (user: HeapUser) => {
  if (!heapInitialized && window?.heap) {
    heapInitialized = true

    const heap = window?.heap
    heap.load('3735665073')
    heap.identify(user.email)
    heap.addUserProperties({
      id: user.id,
      email: user.email,
      role: user.role,
      type: user.type,
      company_id: user.account.id,
      company_name: user.account.name,
      company_type: user.account?.type,
    })
  }
}
