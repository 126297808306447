import { OrderSessionOwner } from 'contractor/server/order_sessions'

export const mockedOrderStoreOrderSessionOwner: OrderSessionOwner | null = null

export const mockedOrderStoreSelectedOrderDirty = false

export const mockedOrderStoreUpdateOrderSession = jest.fn()
export const mockedOrderStoreReleaseOrderSession = jest.fn()
export const mockedOrderStoreUnlockOrderSession = jest.fn()
export const mockedOrderStoreSelectOrder = jest.fn()
export const mockedOrderStoreOrderSessionSubscribe = jest.fn()
export const mockedOrderStoreOrderSessionUnsubscribe = jest.fn()

export const mockedOrderStore = {
  orderSessionOwner: mockedOrderStoreOrderSessionOwner,
  selectedOrderDirty: mockedOrderStoreSelectedOrderDirty,
  updateOrderSession: mockedOrderStoreUpdateOrderSession,
  releaseOrderSession: mockedOrderStoreReleaseOrderSession,
  unlockOrderSession: mockedOrderStoreUnlockOrderSession,
  selectOrder: mockedOrderStoreSelectOrder,
  orderSessionSubscribe: mockedOrderStoreOrderSessionSubscribe,
  orderSessionUnsubscribe: mockedOrderStoreOrderSessionUnsubscribe,
}
