import React, { useState, useEffect, useRef } from 'react'

import { omit } from 'lodash'

import styled from '@emotion/styled'

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import {
  Button,
  Alert,
  Divider,
  Form,
  Input,
  Checkbox,
  Typography,
  message,
  Badge,
  Tooltip,
  Row,
  Col,
  Tabs,
  Spin,
} from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'

import { AddressTable, AddressDrawer } from 'contractor/components/Addresses'
import { getGlobalSafeVendorContact } from 'contractor/helpers/get-global-safe-vendor-contact'
import { useStores } from 'contractor/hooks/use-stores'

import { useMyVendors } from '../context'

type UpdateCompanyVendorContactProps = {
  companyVendorContactId: string
  onFinish?: () => void
  onCancel: () => void
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  & .ant-spin-nested-loading {
    width: 100%;
    height: 100%;

    & .ant-spin-container {
      width: inherit;
      height: inherit;
    }
  }
`

export const UpdateCompanyVendorContact = observer<UpdateCompanyVendorContactProps, DrawerRef>(
  (props, ref) => {
    const { companyVendorContactId, onFinish, onCancel } = props
    const { companyVendorStore } = useStores()

    const { onlyActives } = useMyVendors()

    const { isLoading } = useQuery(() => {
      if (!companyVendorContactId) return
      return companyVendorStore.getCompanyVendorContactById(companyVendorContactId)
    }, [companyVendorContactId])

    const [form] = Form.useForm()

    const drawerCreateAddressRef = useRef<DrawerRef>()

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const companyVendorContact = companyVendorStore.selectedCompanyVendorContact

    const isGlobalizedVendor = !!companyVendorContact?.company_vendor?.vendor
    const isGlobalizedVendorContact = !!companyVendorContact?.contact?.vendor_user

    const handleCancel = () => {
      companyVendorStore.selectedCompanyVendorContact = null
      alertMessage && setAlertMessage('')
      onCancel()
    }

    const handleSubmit = async () => {
      const { vendor: vendorForm, contact: contactform } = await form.validateFields()
      setSubmitting(true)

      try {
        const companyVendorId = companyVendorContact?.company_vendor?.id
        const companyVendorContactId = companyVendorContact?.contact?.id

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const contact = omit(contactform, ['email']) as any

        let params = {}
        if (isGlobalizedVendor && isGlobalizedVendorContact) {
          params = {
            vendorContact: { id: companyVendorContactId, active: contact?.active, nickname: vendorForm?.nickname },
            vendor: { id: companyVendorId, external_vendor_id: vendorForm?.external_vendor_id },
          }
        } else if (isGlobalizedVendor) {
          params = {
            vendorContact: { id: companyVendorContactId, ...contact },
            vendor: { id: companyVendorId, external_vendor_id: vendorForm?.external_vendor_id },
          }
        } else {
          params = {
            vendor: { id: companyVendorId, ...vendorForm },
            vendorContact: { id: companyVendorContactId, ...contact },
          }
        }
        await companyVendorStore.updateVendorContact(params)
        companyVendorStore.getAllCompanyVendors(onlyActives)
        message.success('Successfully updated vendor contact')

        handleCancel()
        onFinish?.()
      } catch (err) {
        setAlertMessage(err.response.data['error'] || 'Unable to update vendor contact')
      } finally {
        setSubmitting(false)
      }
    }

    const handleAddressSaveAsNew = (address: Omit<Address, 'id'>, currentAddress: Address) => {
      return Promise.all([
        companyVendorStore.updateAddress({ ...currentAddress, active: false }),
        companyVendorStore.createAddress(companyVendorContact?.company_vendor?.id, address),
      ])
    }

    const handleCreateAddress = (address) => {
      setSubmitting(true)

      return companyVendorStore.createAddress(companyVendorContact?.company_vendor?.id, address).then(() => {
        drawerCreateAddressRef.current?.close()
        setSubmitting(false)
      })
    }

    const handleUpdateAddress = async (address) => {
      setSubmitting(true)

      await companyVendorStore.updateAddress(address).then(() => {
        drawerCreateAddressRef.current?.close()
        setSubmitting(false)
      })
    }

    useEffect(() => {
      if (companyVendorContact) {
        const { contact, company_vendor } = getGlobalSafeVendorContact(companyVendorContact)

        form.setFieldsValue({
          vendor: { vendor_name: company_vendor.vendor_name, external_vendor_id: company_vendor?.external_vendor_id },
          contact: {
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone_number: contact.phone_number,
            active: contact.active,
            nickname: contact?.nickname,
          },
        })
      } else {
        form.resetFields()
      }
    }, [companyVendorContact])

    return (
      <Drawer
        ref={ref}
        bgGray
        destroyOnClose
        title={
          isGlobalizedVendor ? (
            <Badge
              count={
                <Tooltip
                  placement="bottomLeft"
                  title="This vendor has an account with Subbase and controls their own name and contact details. Feel free to reach out to them if you think anything is incorrect!"
                >
                  <QuestionCircleOutlined style={{ right: -5 }} />
                </Tooltip>
              }
            >
              <Typography.Title level={5} style={{ display: 'block', margin: 0 }}>
                Vendor Information
              </Typography.Title>
            </Badge>
          ) : (
            <Typography.Title level={5} style={{ margin: 0 }}>
              Vendor Information
            </Typography.Title>
          )
        }
      >
        <Wrapper>
          <Spin spinning={isLoading} tip="Loading...">
            <Box p={16} display="flex" height="100%" width="100%" flexDirection="column">
              {alertMessage && (
                <Alert type="error" message={alertMessage} style={{ marginBottom: 16, width: '100%' }} />
              )}

              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                style={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}
              >
                <FlexBoxY width="100%" alignItems="inherit" justifyContent="flex-start">
                  <Form.Item
                    name={['vendor', 'vendor_name']}
                    label="Vendor Name"
                    rules={[{ required: true, message: 'Vendor name is required!' }]}
                  >
                    <Input disabled={isGlobalizedVendor} />
                  </Form.Item>

                  <Form.Item
                    name={['vendor', 'external_vendor_id']}
                    label="Vendor ID"
                    tooltip="Enter the name as it appears in your accounting system."
                  >
                    <Input />
                  </Form.Item>

                  <Tabs
                    defaultActiveKey="1"
                    items={[
                      {
                        label: 'Contact at the Vendor',
                        key: '1',
                        children: (
                          <>
                            <Row gutter={24}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['contact', 'first_name']}
                                  label="First Name"
                                  rules={[{ required: true, message: 'First name is required!' }]}
                                >
                                  <Input disabled={isGlobalizedVendorContact} />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['contact', 'last_name']}
                                  label="Last Name"
                                  rules={[{ required: true, message: 'Last name is required!' }]}
                                >
                                  <Input disabled={isGlobalizedVendorContact} />
                                </Form.Item>
                              </Col>

                              <Col span={24}>
                                <Form.Item name={['contact', 'email']} label="Email">
                                  <Input disabled />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['contact', 'nickname']}
                                  label="Nickname"
                                  tooltip="You can add a nickname to the contact and it will appear in all places right after the contact name, like: [First Name] [Last Name] ([Nickname])"
                                >
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={12}>
                                <Form.Item name={['contact', 'phone_number']} label="Phone Number">
                                  <Input disabled={isGlobalizedVendorContact} />
                                </Form.Item>
                              </Col>

                              <Col span={24}>
                                <Form.Item name={['contact', 'active']} label="Active" valuePropName="checked">
                                  <Checkbox />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ),
                      },
                      {
                        label: 'Vendor Pickup Addresses',
                        key: '2',
                        children: (
                          <>
                            <AddressTable
                              addresses={companyVendorStore.selectedCompanyVendorContact?.company_vendor.addresses}
                              onSubmit={handleUpdateAddress}
                              onSaveAsNew={handleAddressSaveAsNew}
                              hideSelect
                              isDrawer
                            />
                            <AddressDrawer
                              ref={drawerCreateAddressRef}
                              onClose={() => drawerCreateAddressRef.current?.close()}
                              onSubmit={handleCreateAddress}
                              hideSelect
                            />
                            <Button
                              block
                              type="primary"
                              onClick={() => drawerCreateAddressRef.current?.show()}
                              style={{ marginTop: 12 }}
                            >
                              Add Address
                            </Button>
                          </>
                        ),
                      },
                    ]}
                  />
                </FlexBoxY>

                <Divider />

                <FlexBoxX justifyContent="space-between" flexGrow={0}>
                  <Button onClick={handleCancel} loading={isSubmitting || isLoading} style={{ width: 100 }}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting || isLoading}
                    style={{ width: 100 }}
                  >
                    Save
                  </Button>
                </FlexBoxX>
              </Form>
            </Box>
          </Spin>
        </Wrapper>
      </Drawer>
    )
  },
  { forwardRef: true },
)
