import { Comment } from 'common/server/comment'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderMaterial } from 'common/server/orders'
import {
  File,
  IntegrationsSourceSystem,
  InvoiceInboxFilesStates,
  InvoiceInboxStates,
  InvoiceInboxSubStates,
  InvoiceSourceType,
  InvoicesStatuses,
  RelationshipStockStatus,
} from 'common/server/server_types'
import { Unit } from 'common/server/units'

import { InvoiceStates } from 'contractor/server/company_settings/invoice_states'
import { InvoiceInboxHit } from 'contractor/server/invoices/inbox'
import { ShowOrderResponse } from 'contractor/server/orders'

export interface InvoiceOrder {
  order: ShowOrderResponse
  materials: { [deliveryId: string]: OrderMaterial[] }
}

export interface PdfFile {
  id: string
  url: string
  filename: string
  state: InvoiceInboxFilesStates
  fail_message?: string
  invoice_flow_id: string
  invoice_inbox_attachment_id: string
}

export interface ShortInvoiceResponse {
  id: string
  number: string
  discarded_at?: Date
}

export interface DuplicatedInvoices {
  url: string
  number: string
  total_amount: number
  created_at: Date
}

export interface ShowInvoiceUploadResponse {
  id: string
  created_at: string
  updated_at: string
  uploaded_by_company_user: {
    id: string
    user: {
      id: string
      name: string
    }
  }
  uploaded_by_company_user_id: string
  invoice_inbox_id: string
  state: InvoiceInboxStates
  sub_state: InvoiceInboxSubStates
  fail_reason?: string
  pdf_files?: PdfFile[]
  invoices: ShortInvoiceResponse[]
  has_reported_issue: boolean
}

export interface ShowInvoiceMailResponse {
  objectID: string
  id: string
  from?: string[]
  to?: string[]
  cc?: string[]
  bcc?: string[]
  created_at: string
  updated_at: string
  subject: string
  body: string
  company_id: string
  company: { id: string; name: string }
  mail_attachments?: File[]
  invoice_inbox_id: string
  state: InvoiceInboxStates
  sub_state: InvoiceInboxSubStates
  fail_reason?: string
  pdf_files?: PdfFile[]
  invoices: ShortInvoiceResponse[]
  has_reported_issue: boolean
}

export interface InvoiceMaterial {
  id: string
  row: number
  product_code?: string
  description?: string
  uom?: string
  quantity_shipped?: number
  unit_price?: number
  extended_price?: number
  tax?: number
  unit?: Unit
  invoice_id: string
  order_materials_count: number
  accepts_tax_split: boolean
  tax_split: number

  // Cost code will be the same as the order material linked
  cost_code_id?: string
  cost_code?: CostCode
  cost_code_phase_id?: string
  cost_code_phase?: CostCodePhase

  // Used to link order material with invoice material
  order_materials?: OrderMaterial[]

  // Control fields
  auto_calculated_ext_price: boolean
  matched_extended_price: boolean

  // Used to show the remaining values inverted
  is_remaining: boolean

  // Retainage
  retainage_type?: string
  retainage_percentage: number
  retainage_amount: number
}

export interface InvoiceProject {
  id: string
  name: string
  tax_line_items: { enabled: boolean }
}

export interface InvoiceResponse {
  id: string
  watcher_ids: string[]
  name?: string
  number?: string
  status: InvoicesStatuses
  state: InvoiceStates
  posted_state: InvoiceStates
  duplicated_invoices?: DuplicatedInvoices[]

  // Totals and Other pricing fields
  discount_amount?: number
  shipping_cost?: number
  other_costs?: number
  total_amount?: number
  subtotal_amount?: number
  tax_amount?: number
  grand_total?: number
  discount_material_amount?: number
  invoice_grand_total?: number
  eligible_tax_split_total?: number

  // Original values from the Nanonets
  extracted_seller_name?: string
  extracted_invoice_number?: string
  extracted_document_date?: string
  extracted_subtotal?: string
  extracted_total_tax?: string
  extracted_invoice_amount?: string
  extracted_order_number?: string // Came from extracted data
  orders: {
    id: string
    order_number: string
    vendor_name?: string
    grand_total?: string
  }[]
  project?: InvoiceProject
  projects: InvoiceProject[]
  invoice_source_id: string
  invoice_source_type: InvoiceSourceType
  invoice_inbox_id: string
  assigned_to_company_user_id?: string
  assigned_to_name?: string
  seller_name?: string
  source_from?: string
  source_created_at?: string
  due_date?: string
  document_date?: string
  accounting_date?: string
  created_at: string
  updated_at: string
  extracted_files: {
    url: string
    filename: string
  }[]
  tags?: string[]
  rejection_reason?: string
  integration: {
    last_sync_at: Nullable<string>
    status: RelationshipStockStatus
    source: IntegrationsSourceSystem
    errors: {
      fail_message: string
      fail_body: string
      failed_at: string
    }[]
    web_url: Nullable<string>
  }
  comments: Comment[]
  company_vendor_id?: string
  company_vendor?: { id: string; vendor_name: string; vendor?: { id: string; name: string } }
  invoice_materials: InvoiceMaterial[]
  marked_extracted_at?: string
  marked_reconciled_at?: string
  decimal_precision: number
  invoice_inbox_attachment?: {
    id: string
    state: InvoiceInboxFilesStates
  }
  invoice_inbox?: {
    id: string
    has_reported_issue: boolean
  }
  discarded_at?: string
}

// Helpers methods tha make the transition easy
export const invoiceMailToInvoiceHit = (mailResponse: Nullable<ShowInvoiceMailResponse>): Nullable<InvoiceInboxHit> => {
  if (!mailResponse) return null
  return {
    id: mailResponse.invoice_inbox_id,
    state: mailResponse.state,
    sub_state: mailResponse.sub_state,
    created_at: mailResponse.created_at,
    invoice_source_type: 'InvoiceMail',
    origin: 'Email Received',
    invoice_source_id: mailResponse.id,
    from: mailResponse.from?.[0],
    subject: mailResponse.subject,
    files: mailResponse.pdf_files,
    invoice_count: mailResponse.invoices.length,
    fail_reason: mailResponse.fail_reason,
  }
}

export const invoiceUploadToInvoiceHit = (
  uploadResponse: Nullable<ShowInvoiceUploadResponse>,
): Nullable<InvoiceInboxHit> => {
  if (!uploadResponse) return null
  return {
    id: uploadResponse.invoice_inbox_id,
    state: uploadResponse.state,
    sub_state: uploadResponse.sub_state,
    created_at: uploadResponse.created_at,
    invoice_source_type: 'InvoiceUpload',
    origin: 'Uploaded',
    invoice_source_id: uploadResponse.id,
    files: uploadResponse.pdf_files,
    invoice_count: uploadResponse.invoices.length,
    fail_reason: uploadResponse.fail_reason,
  }
}
