import React from 'react'

import { NumberOutlined, WalletOutlined } from '@ant-design/icons'
import { Tooltip, Typography, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, Box, BoxProps } from 'common/components/boxes'
import Tags from 'common/components/tags'
import { getPONumberTitle } from 'common/helpers/get-po-number-title'
import { useMediaQuery } from 'common/hooks/use-media-query'

import { POHistory } from 'contractor/components/POHistory'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { Invoices } from './invoices'
import { LevelingPopover } from './LevelingPopover'
import { ProjectsDrawer } from './ProjectsDrawer'
import { VendorContactsDrawer } from './VendorContactsDrawer'

type OrderInfoProps = BoxProps & {
  isPoLocked?: boolean
}

const fontSize = 12

const OrderInfo = observer<OrderInfoProps>((props) => {
  const { orderStore, userStore, companySettingStore } = useStores()

  const isMobileScreen = useMediaQuery('lg')

  const quoteNumberEnabled = useFlag('quote_number')

  const { watcher_ids, tags, order_number, quote_number, count_order_package, state, commitment_id, commitment } =
    orderStore.selectedOrder

  const editDisabled = userStore.cannotSendAndUpdateOrders || props.isPoLocked

  const editableOrderNumber = !editDisabled && {
    onChange: (value = '') => orderStore.updateSelectedOrder('order_number', value),
  }

  const editableQuoteNumber = !editDisabled && {
    onChange: (value = '') => orderStore.updateSelectedOrder('quote_number', value),
  }

  const showLeveling = userStore.canUseRfqLeveling && count_order_package > 1

  const poNumberTitle = getPONumberTitle(state)

  if (isMobileScreen) {
    return (
      <Box bg="white" p={16}>
        <Box mb={24}>
          <Typography.Text type="secondary" style={{ fontSize }}>
            PROJECT
          </Typography.Text>
          <ProjectsDrawer disabled={editDisabled} />
        </Box>

        <Box mb={24}>
          <Typography.Text type="secondary" style={{ fontSize }}>
            VENDOR
          </Typography.Text>
          <VendorContactsDrawer disabled={editDisabled} />
        </Box>

        <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
          <Typography.Text type="secondary" style={{ fontSize }}>
            {poNumberTitle?.toUpperCase()}
          </Typography.Text>
          <FlexBoxX flexGrow={0} px={8} py={2}>
            <NumberOutlined />
            <Typography.Text style={{ marginLeft: 4 }} editable={editableOrderNumber}>
              {order_number || poNumberTitle}
            </Typography.Text>
          </FlexBoxX>
        </Box>

        {quoteNumberEnabled && !commitment_id && (
          <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize }}>
              QUOTE NUMBER
            </Typography.Text>
            <FlexBoxX flexGrow={0} px={8} py={2}>
              <NumberOutlined />
              <Typography.Text style={{ marginLeft: 4 }} editable={editableQuoteNumber}>
                {quote_number || 'Quote Number'}
              </Typography.Text>
            </FlexBoxX>
          </Box>
        )}

        {!!commitment_id && (
          <Box mb={24} width="fit-content" alignItems="flex-start" display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize }}>
              COMMITMENT
            </Typography.Text>
            <FlexBoxX flexGrow={0} px={8} py={2}>
              <WalletOutlined />
              <Typography.Text style={{ marginLeft: 4 }}>{commitment?.name}</Typography.Text>
            </FlexBoxX>
          </Box>
        )}

        {showLeveling && (
          <Box mb={24}>
            <LevelingPopover />
          </Box>
        )}

        {!userStore.isMaterialRequester && (
          <Box mb={24}>
            <Typography.Text>Tags</Typography.Text>
            <Tags
              value={tags}
              disabled={editDisabled}
              onChange={(tags) => orderStore.updateSelectedOrder('tags', tags)}
              tags={orderStore.orderTags}
            />
          </Box>
        )}

        <Space>
          <Invoices />

          {!userStore.isMaterialRequester && (
            <Box width="fit-content">
              <WatchersDrawer
                value={companySettingStore.possibleUsers
                  .filter((user) => watcher_ids?.includes(user.company_user_id))
                  .map((user) => makeCompanyUserWatcherOption(user))}
                onChange={(watchers = []) =>
                  orderStore.updateSelectedOrder(
                    'watcher_ids',
                    watchers?.map((watcher) => watcher.value),
                  )
                }
                isDisabled={editDisabled}
              />
            </Box>
          )}
        </Space>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      style={{ columnGap: 16, rowGap: 2 }}
      px={{ _: 24, xs: 0 }}
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent={{ _: 'space-between', xs: 'flex' }}
        style={{ columnGap: 16, rowGap: 2 }}
      >
        <Box>
          <ProjectsDrawer disabled={editDisabled} />
        </Box>

        <Box>
          <VendorContactsDrawer disabled={editDisabled} />
        </Box>

        <POHistory poHistory={orderStore.selectedOrder?.po_history} orderState={orderStore.selectedOrder?.state}>
          <Box>
            <Tooltip title={poNumberTitle} placement="top">
              <FlexBoxX flexGrow={0} px={8} py={2}>
                <NumberOutlined />
                <Typography.Paragraph
                  style={{ marginBottom: '0', marginLeft: 4, whiteSpace: 'break-spaces' }}
                  editable={editableOrderNumber}
                >
                  {order_number || poNumberTitle}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        </POHistory>

        {quoteNumberEnabled && !commitment_id && (
          <Box>
            <Tooltip title="Quote number" placement="bottom">
              <FlexBoxX flexGrow={0} px={8} py={2}>
                <NumberOutlined />
                <Typography.Paragraph
                  style={{ marginBottom: '0', marginLeft: 4, whiteSpace: 'break-spaces' }}
                  editable={editableQuoteNumber}
                >
                  {quote_number || 'Quote Number'}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        )}

        {!!commitment_id && (
          <Box>
            <Tooltip title="Commitment" placement="bottom">
              <FlexBoxX flexGrow={0} px={8} py={2}>
                <WalletOutlined />
                <Typography.Paragraph style={{ marginBottom: '0', marginLeft: 4, whiteSpace: 'break-spaces' }}>
                  {commitment?.name}
                </Typography.Paragraph>
              </FlexBoxX>
            </Tooltip>
          </Box>
        )}

        {showLeveling && (
          <Box>
            <LevelingPopover />
          </Box>
        )}

        {!userStore.isMaterialRequester && (
          <Box minWidth="175px">
            <Tags
              value={tags}
              disabled={editDisabled}
              onChange={(tags) => orderStore.updateSelectedOrder('tags', tags)}
              tags={orderStore.orderTags}
            />
          </Box>
        )}

        <Invoices />
      </Box>

      {!userStore.isMaterialRequester && (
        <Box>
          <WatchersDrawer
            value={companySettingStore.possibleUsers
              .filter((user) => watcher_ids?.includes(user.company_user_id))
              .map((user) => makeCompanyUserWatcherOption(user))}
            onChange={(watchers = []) =>
              orderStore.updateSelectedOrder(
                'watcher_ids',
                watchers?.map((watcher) => watcher.value),
              )
            }
            isDisabled={editDisabled}
            makeOption={makeCompanyUserWatcherOption}
          />
        </Box>
      )}
    </Box>
  )
})

export default OrderInfo
