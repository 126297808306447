import React from 'react'

import { ClockCircleOutlined } from '@ant-design/icons'
import { Input, Modal, Typography, Checkbox, Divider, Space } from 'antd'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/constants'

import { observer } from 'mobx-react-lite'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY, Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { OrderStates } from 'common/server/server_types'

import { POHistory } from 'contractor/components/POHistory'
import { TermsAndConditions, mapOptions } from 'contractor/components/TermsAndConditions'
import { useStores } from 'contractor/hooks/use-stores'

interface ModalConfirmPurchaseProps {
  onClose: () => void
  onFinish: () => void
  open: boolean
  isLoading?: boolean
  syncOrderWithIntegration: boolean
  onChangeSyncOrderWithIntegration: (checked: boolean) => void
  cancelOtherPackageOrders?: boolean
  onChangeCancelOtherPackageOrders?: (visible: boolean) => void
  costSavingValue?: number
  orderState?: OrderStates
}

export const ModalConfirmPurchase = observer<ModalConfirmPurchaseProps>(
  ({
    onClose,
    open,
    onFinish,
    isLoading,
    syncOrderWithIntegration,
    onChangeSyncOrderWithIntegration,
    cancelOtherPackageOrders,
    onChangeCancelOtherPackageOrders,
    costSavingValue,
    orderState,
  }) => {
    const { orderStore, userStore, integrationStore, companySettingStore } = useStores()

    if (!orderStore.selectedOrder) {
      return null
    }

    const onClickConfirm = () => {
      trackEvent(
        Events.CLICK_CONFIRM_PURCHASE_PLACE_ORDER_MODAL,
        window.location.pathname,
        {
          po_number: orderStore.selectedOrder.order_number,
        },
        Tool.AMPLITUDE,
      )
      onFinish()
    }

    return (
      <Modal
        title="Confirm Purchase"
        open={open}
        onOk={onClickConfirm}
        okText="Confirm"
        onCancel={onClose}
        okButtonProps={{ loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
          <Typography.Text type="secondary">PO number (optional)</Typography.Text>
          <Space>
            <Input
              style={{ width: '250px' }}
              placeholder="PO number (optional)"
              onChange={(e) => orderStore.updateSelectedOrder('order_number', e.target.value)}
              value={orderStore.selectedOrder.order_number}
            />
            <POHistory poHistory={orderStore.selectedOrder?.po_history} orderState={orderState}>
              <ClockCircleOutlined />
            </POHistory>
          </Space>
        </FlexBoxY>

        <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
          {userStore.canUseIntegrations && integrationStore.connected && integrationStore.purchaseOrderSyncEnabled && (
            <Checkbox
              onChange={(e) => {
                localStorage.setItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY, `${e.target.checked}`)
                onChangeSyncOrderWithIntegration(e.target.checked)
              }}
              checked={syncOrderWithIntegration}
              style={{ marginTop: 16 }}
            >
              Sync purchase order with {integrationStore.getIntegrationName()}
            </Checkbox>
          )}

          <Typography.Text type="secondary" italic style={{ fontSize: 12 }}>
            PDF of PO will be automatically created
          </Typography.Text>

          {!!companySettingStore.activeTermsAndConditions?.length && (
            <>
              <Divider style={{ margin: '12px 0' }} />
              <TermsAndConditions
                style={{ width: 280 }}
                type="PO"
                options={companySettingStore.activeTermsAndConditions?.map(mapOptions)}
                onChange={(termsAndConditionId) =>
                  orderStore.updateSelectedOrder('terms_and_condition_id', termsAndConditionId)
                }
                value={orderStore.selectedOrder?.terms_and_condition_id}
              />
            </>
          )}
        </FlexBoxY>

        {/* Only show cost saving message if the savings is greater than 0 */}
        {costSavingValue > 0 && (
          <Box width="100%">
            <Divider />

            <Typography.Text strong style={{ textAlign: 'center', display: 'block' }}>
              Congrats on your
              <Typography.Text type="success" underline style={{ fontSize: 16, marginLeft: 4 }}>
                {currencyFormatter(costSavingValue, 2)} in savings!
              </Typography.Text>
            </Typography.Text>
          </Box>
        )}

        {!!onChangeCancelOtherPackageOrders && (
          <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
            <Checkbox
              onChange={(e) => onChangeCancelOtherPackageOrders(e.target.checked)}
              checked={cancelOtherPackageOrders}
              style={{ marginTop: 16 }}
            >
              Cancel all other unplaced quotes from the same RFQ
            </Checkbox>
          </FlexBoxY>
        )}
      </Modal>
    )
  },
)
