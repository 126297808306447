import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Spin, Tooltip, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import UploaderModal from 'common/components/uploaderModal'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

const statesToShowPO = [
  OrderStates.ORDERED,
  OrderStates.PARTIALLY_SHIPPED,
  OrderStates.SHIPPED,
  OrderStates.DELIVERED,
  OrderStates.CANCELLED,
]

const VENDOR_FILES_KEY = 'vendor_files'
const PURCHASER_FILES_KEY = 'purchaser_files'
const PURCHASER_ORDER_FILE_KEY = 'purchase_order'
const REQUEST_FOR_QUOTE_FILE_KEY = 'request_for_quote'
const QUOTE_FILE_KEY = 'quote'

interface AttachmentsProps {
  canInputVendorData: boolean
  isPoLocked?: boolean
}

export const Attachments = observer(({ canInputVendorData, isPoLocked }: AttachmentsProps) => {
  const { orderStore, uploaderStore, userStore, companySettingStore } = useStores()

  const downloadRequestForQuoteEnabled = companySettingStore?.otherSettings?.email_settings?.include_pdf_attachments

  const { purchaser_files, purchase_order, request_for_quote, quote, vendor_files } = orderStore.selectedOrder

  const editDisabled = userStore.cannotSendAndUpdateOrders || isPoLocked

  const isCanceled = orderStore.selectedOrder?.state === OrderStates.CANCELLED
  const showPurchaseOrderLoading = !isCanceled && !purchase_order

  React.useEffect(() => {
    ;[
      VENDOR_FILES_KEY,
      PURCHASER_FILES_KEY,
      PURCHASER_ORDER_FILE_KEY,
      REQUEST_FOR_QUOTE_FILE_KEY,
      QUOTE_FILE_KEY,
    ].forEach((key) => {
      uploaderStore.resetUploads(key)
    })

    uploaderStore.addExistingFiles(VENDOR_FILES_KEY, vendor_files)
    uploaderStore.addExistingFiles(PURCHASER_FILES_KEY, purchaser_files)
    uploaderStore.addExistingFiles(PURCHASER_ORDER_FILE_KEY, purchase_order)
    uploaderStore.addExistingFiles(REQUEST_FOR_QUOTE_FILE_KEY, request_for_quote)
    uploaderStore.addExistingFiles(QUOTE_FILE_KEY, quote)
  }, [orderStore.selectedOrder?.id, orderStore.selectedOrder?.updated_at])

  return (
    <Card
      title="Attachments"
      contentProps={{ display: 'flex', flexDirection: { _: 'column', sm: 'row' } }}
      minHeight="208px"
    >
      <Box display="flex" flexDirection="column" width={{ _: '100%', sm: '50%' }}>
        <Typography.Paragraph strong>My Attachments</Typography.Paragraph>

        {orderStore.selectedOrder?.state === OrderStates.QUOTED && downloadRequestForQuoteEnabled && (
          <UploaderModal
            disabled
            multiple={false}
            uploadKey={REQUEST_FOR_QUOTE_FILE_KEY}
            title="Request For Quote"
            fileList={uploaderStore.fileList(REQUEST_FOR_QUOTE_FILE_KEY)}
          />
        )}

        {userStore.canUseAutoPoGeneration && statesToShowPO.includes(orderStore.selectedOrder?.state) && (
          <>
            <UploaderModal
              disabled
              multiple={false}
              uploadKey={PURCHASER_ORDER_FILE_KEY}
              title="Purchase Order"
              fileList={uploaderStore.fileList(PURCHASER_ORDER_FILE_KEY)}
              onPreview={(file) => {
                trackEvent(Events.DOWNLOAD_PO, window.location.href, {
                  file_name: file?.name,
                  user_id: userStore.currentUser.id,
                  user_email: userStore.currentUser.email,
                  company_id: userStore.currentUser.company_id,
                  company_name: userStore.currentUser.company_name,
                })
              }}
            />
            {showPurchaseOrderLoading && (
              <Tooltip title="Your PO will be generated in a few seconds." placement="topLeft">
                <Space style={{ width: 'max-content' }}>
                  <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />
                  <Typography.Text type="secondary">Loading...</Typography.Text>
                </Space>
              </Tooltip>
            )}
          </>
        )}

        <Box mt={12}>
          <UploaderModal
            title="Other Attachments"
            multiple
            disabled={editDisabled}
            uploadKey={PURCHASER_FILES_KEY}
            onChange={() => orderStore.setOrderDirty()}
            fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
            uploadButtonText="Upload"
          />
        </Box>
      </Box>

      <Box
        flexGrow={1}
        mx={{ _: 0, sm: 12 }}
        my={{ _: 12, sm: 0 }}
        borderRightStyle="solid"
        borderBottomStyle="solid"
        borderRightWidth={{ _: 'none', sm: '1px' }}
        borderBottomWidth={{ _: '1px', sm: 'none' }}
        borderRightColor="gray-4"
        borderBottomColor="gray-4"
      />

      <Box display="flex" flexDirection="column" width={{ _: '100%', sm: '50%' }}>
        <Typography.Paragraph strong>Vendor Attachments</Typography.Paragraph>
        <UploaderModal
          multiple={false}
          uploadKey={QUOTE_FILE_KEY}
          title="Quote"
          disabled={!canInputVendorData || isPoLocked}
          uploadButtonText={canInputVendorData ? 'Attach quote' : null}
          onRemoveUpload={uploaderStore.removeUpload}
          onAddNewUpload={uploaderStore.addNewUpload}
          onResetUploads={uploaderStore.resetUploads}
          onSetUploadError={uploaderStore.setUploadError}
          onUpdateUpload={uploaderStore.updateUpload}
          onChange={orderStore.setOrderDirty}
          fileList={uploaderStore.fileList(QUOTE_FILE_KEY)}
        />
        <Box mt={12}>
          <UploaderModal
            multiple
            uploadKey={VENDOR_FILES_KEY}
            title="More Attachments"
            disabled
            onChange={orderStore.setOrderDirty}
            fileList={uploaderStore.fileList(VENDOR_FILES_KEY)}
          />
        </Box>
      </Box>
    </Card>
  )
})
