import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import _ from 'lodash'

import { Button, Divider, Form, message, Popconfirm, Select, Space, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'
import { Address } from 'common/server/addresses'

import { useStores } from 'contractor/hooks/use-stores'

import AddressForm from './address_form'

export type AddressProps = {
  onClose: () => void
  onSubmit: (address: Address, id?: { company_id?: string; project_id?: string; company_vendor_id?: string }) => void
  onSaveAsNew?: (newAddress: Omit<Address, 'id'>, currentAddress: Address) => void
  address?: Address
  title?: string
  project_ids?: string[]
  hideCompanyAddress?: boolean
  hideProjectAddress?: boolean
  hideVendorAddress?: boolean
  hideSelect?: boolean
  companyVendorId?: string
  showMainOfficeLabel?: boolean
}

type OwnedAddress = {
  company_vendor_id?: string
  company_id?: string
  project_id?: string
}

// Using a Form here from Antd but besides for some nice email validation, don't think it buys us much
const AddressDrawer = observer<AddressProps, DrawerRef>(
  (props, ref) => {
    const {
      onClose,
      address,
      onSubmit,
      onSaveAsNew,
      title,
      project_ids,
      hideVendorAddress,
      hideCompanyAddress,
      hideProjectAddress,
      hideSelect,
      companyVendorId,
      showMainOfficeLabel,
    } = props

    const [form] = Form.useForm()
    const addOrEdit = address ? 'Update' : 'Add'
    const { projectStore, userStore, companyVendorStore } = useStores()

    const location = useLocation()

    const [showUpdateTootip, setShowUpdateTootip] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [newAddressOwnedId, setNewAddressOwnedId] = useState<OwnedAddress>({})
    const [formAddress, setFormAddress] = useState<Address>({
      active: addOrEdit === 'Add' ? true : address.discarded_at === null,
      ...address,
    })

    useQuery(companyVendorStore.maybeGetAllCompanyVendorsToSelect)

    const newAddressProjectOptions = project_ids
      ? projectStore.projects?.filter((project) => project_ids.includes(project.id))
      : []

    const company_id = userStore.currentUser?.company_id

    const handleFinish = async () => {
      const values = (await form.validateFields()) as Address
      setSubmitting(true)

      try {
        await onSubmit({ ...values, id: address?.id }, newAddressOwnedId)
        onClose()
        form.resetFields()

        message.success(`Successfully ${address ? 'updated' : 'added'} address`)
      } catch (err) {
        message.error(`Unable to ${addOrEdit} address ${_.get(err, 'response.data.error', '')}`, 3)
      } finally {
        setSubmitting(false)
        trackEvent(Events.ADD_ADDRESS, location.pathname.slice(1))
      }
    }

    const handleSaveAsNew = async () => {
      const values = form.getFieldsValue() as Address
      setSubmitting(true)

      try {
        await form.validateFields()
        await onSaveAsNew(values, address)
        onClose()
        form.resetFields()

        message.success('Successfully saved as a new address')
      } catch (err) {
        message.error(err?.response?.data?.error || 'Unable to save address')
      } finally {
        setSubmitting(false)
        trackEvent(Events.ADD_ADDRESS, location.pathname.slice(1))
      }
    }

    useEffect(() => {
      setFormAddress({ active: addOrEdit === 'Add' ? true : address.discarded_at === null, ...address })
    }, [address])

    const companyVendors = companyVendorId
      ? companyVendorStore.companyVendorsSelect.filter((companyVendor) => companyVendor.id === companyVendorId)
      : companyVendorStore.companyVendorsSelect

    const value =
      newAddressOwnedId?.['company_id'] || newAddressOwnedId?.['project_id'] || newAddressOwnedId?.['company_vendor_id']

    return (
      <Drawer bgGray title={title ? title : `${addOrEdit} an Address`} ref={ref}>
        <Box display="flex" flexDirection="column" p={16} width="100%" height="100%" overflowY="auto">
          {!hideSelect && (
            <Select
              value={value}
              style={{ width: '100%', minWidth: '250px', marginBottom: 20 }}
              onChange={(_, more) => setNewAddressOwnedId(more['address_owner'])}
              placeholder="Address type"
              data-cy="address-type-select"
            >
              {!hideCompanyAddress && (
                <Select.Option value={company_id} address_owner={{ company_id }}>
                  Company Address
                </Select.Option>
              )}

              {!hideProjectAddress && (
                <Select.OptGroup label="Project Address">
                  {newAddressProjectOptions?.map((project) => (
                    <Select.Option value={project.id} key={project.id} address_owner={{ project_id: project.id }}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}

              {!hideVendorAddress && (
                <Select.OptGroup label="Vendor Address">
                  {companyVendors?.map((companyVendor) => (
                    <Select.Option
                      value={companyVendor.id}
                      key={companyVendor.id}
                      address_owner={{ company_vendor_id: companyVendor.id }}
                    >
                      {companyVendor?.vendor?.name || companyVendor?.vendor_name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}
            </Select>
          )}

          <AddressForm
            address={formAddress}
            form={form}
            showMainOfficeLabel={showMainOfficeLabel}
            showActive={!!address}
          />

          <Divider />

          <Box display="flex" justifyContent="space-between">
            <Button onClick={onClose} loading={isSubmitting} style={{ width: 100 }}>
              Cancel
            </Button>

            {address && onSaveAsNew ? (
              <Space>
                <Tooltip title="Will save your changes as a new address and mark the current version as inactive.">
                  <Button onClick={handleSaveAsNew} loading={isSubmitting}>
                    Save as New
                  </Button>
                </Tooltip>

                <Tooltip
                  title="Will update all existing uses of this address but will not regenerate any PDFs or notify your vendors."
                  placement="topRight"
                  open={showUpdateTootip}
                  onOpenChange={setShowUpdateTootip}
                >
                  <Popconfirm
                    title={
                      <>
                        Are you sure you would like to <br />
                        update all existing uses of this address? <br />
                        This will not regenerate any PDFs or notify your vendors.
                      </>
                    }
                    placement="topRight"
                    onConfirm={handleFinish}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" loading={isSubmitting} onClick={() => setShowUpdateTootip(false)}>
                      {addOrEdit}
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Space>
            ) : (
              <Button data-cy="add-or-edit-address-button" type="primary" onClick={handleFinish} loading={isSubmitting}>
                {addOrEdit}
              </Button>
            )}
          </Box>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)

export default AddressDrawer
