import React, { createContext, useContext } from 'react'

import constants from '../constants'

import { observer } from 'mobx-react-lite'

import { useLockPo } from 'contractor/hooks/use-lock-po'
import { useStores } from 'contractor/hooks/use-stores'

import { useOrderActions, useOrderData, useOrderEffects, useOrderPermissions, useOrderStates } from '../hooks'
import { OrderDetailContextProps } from './types'

const OrderDetailContext = createContext<OrderDetailContextProps>({} as OrderDetailContextProps)

export const useOrderDetail = () => useContext(OrderDetailContext)

export const OrderDetailProvider = observer(({ children }) => {
  const { orderStore, userStore } = useStores()
  const { orderLocked: isPoLocked, orderSession: session } = useLockPo(orderStore?.selectedOrder)

  const data = useOrderData()
  const permissions = useOrderPermissions()
  const orderStates = useOrderStates(permissions)
  const actions = useOrderActions(orderStates, data)
  useOrderEffects(actions)

  const states = {
    isLoading: data.isLoading,
    isPoLocked,
    showLockIcon: isPoLocked || userStore.cannotSendAndUpdateOrders,
    showInvoicesTab: !!orderStore.selectedOrder?.invoices?.length && userStore.canUseInvoices,
    ...orderStates,
  }

  return (
    <OrderDetailContext.Provider value={{ actions, constants, data, permissions, states, session }}>
      {children}
    </OrderDetailContext.Provider>
  )
})

export const withOrderDetailProvider = (Component) => (props) => {
  return (
    <OrderDetailProvider>
      <Component {...props} />
    </OrderDetailProvider>
  )
}
