import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { LockOutlined } from '@ant-design/icons'
import { Modal, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Page } from 'common/components/Page'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyMaterialsV2 } from 'contractor/pages/@v2/CompanyMaterials'
import { CostCodes } from 'contractor/pages/CompanyMaterials/CostCodes/cost_codes'
import { PhaseCodes } from 'contractor/pages/CompanyMaterials/PhaseCode/phase_codes'

const CompanyMaterialsWrapper = observer(() => {
  const { userStore, companySettingStore } = useStores()

  const history = useHistory()
  const location = useLocation()
  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    {
      label: 'Material Database',
      key: 'company-materials',
      children: <CompanyMaterialsV2 />,
    },
    userStore.canUseCostCode
      ? { label: 'Cost Code', key: 'cost-codes', children: <CostCodes /> }
      : {
          label: (
            <span
              onClick={() => {
                Modal.info({
                  content: (
                    <Typography.Text>
                      Users on the <Typography.Text strong>Basic</Typography.Text> plan are unable to leverage{' '}
                      <Typography.Text strong>Cost Codes</Typography.Text>. Let us know if you&apos;d like to upgrade!
                    </Typography.Text>
                  ),
                })
              }}
            >
              <LockOutlined />
              Cost Code
            </span>
          ),
          key: 'cost-codes-disabled',
          disabled: true,
        },
    companySettingStore?.otherSettings?.cost_code_settings?.independent_phase_codes_enabled
      ? { label: 'Phase Code', key: 'phase-codes', children: <PhaseCodes /> }
      : undefined,
  ]

  return (
    <Page>
      <Page.Tabs
        activeKey={activeKey || 'company-materials'}
        onChange={(key) => {
          history.push({
            search: new URLSearchParams({ tab: key }).toString(),
          })
        }}
        items={tabItems}
        destroyInactiveTabPane
      />
    </Page>
  )
})

export default CompanyMaterialsWrapper
