import React, { useState } from 'react'

import { Alert, Radio, Form, Input, Typography, Button, Space, Divider, Row, Col, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { Visibility } from 'common/components/Visibility'
import { noticeError } from 'common/helpers/new_relic'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact, CreateVendorContactRequest } from 'contractor/server/company_vendor_contacts'
import { VendorUser } from 'contractor/server/vendors'

import { useMyVendors } from '../context'
import { CompanyVendorContactAutoComplete } from './company_vendor_contact_autocomplete'
import { CompanyVendorSelector, Option as CompanyVendorSelectorOption } from './company_vendor_selector'
import { GlobalizedVendorCard } from './globalized_vendor_card'

export type CreateCompanyVendorContactProps = {
  onFinish?: (newVendor: CompanyVendorContact) => void
  onCancel: () => void
  hideShowSaveAndAddAnotherButton?: boolean
  selectedCompanyVendorId?: string
  selectedVendorId?: string
  hideNotification?: boolean
  hideTitle?: boolean
  withDrawer?: boolean
}

export const CreateCompanyVendorContact = observer<CreateCompanyVendorContactProps, DrawerRef>(
  (props, ref) => {
    const {
      onFinish,
      onCancel,
      hideShowSaveAndAddAnotherButton,
      hideNotification,
      selectedCompanyVendorId,
      selectedVendorId,
      hideTitle,
      withDrawer = true,
    } = props
    const { companyVendorStore, vendorStore } = useStores()

    const { onlyActives } = useMyVendors()

    // Get all vendors and company vendors to show on select
    useQuery(vendorStore.getAllVendors)
    useQuery(companyVendorStore.maybeGetAllCompanyVendorsToSelect)
    // Get all vendors users to show on auto-complete
    useQuery(vendorStore.getAllVendorsUsers)

    const [alertMessage, setAlertMessage] = useState('')
    const [vendorUser, setVendorUser] = useState<VendorUser>(null)
    const [isExistingVendor, setExistingVendor] = useState(true)
    const [contactDomain, setContactDomain] = useState('')
    const [matchVendorBydomain, setMatchVendorByDomain] = useState<CompanyVendorSelectorOption>(null)
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()

    const resetComponent = () => {
      setAlertMessage('')
      setSubmitting(false)
      setExistingVendor(true)
      setVendorUser(null)
      setMatchVendorByDomain(null)
      setContactDomain('')
      form.resetFields()
    }

    const handleCancel = () => {
      resetComponent()
      onCancel()
    }

    /*
      We have 3 scenarios on the UI, 2 and 3 has the same sub paths.
      1. User creates a new vendor.
      2. User selectes a existing vendor.
        2.1. User selects their own company vendor.
        2.2. User selects a global vendor.
      3. User selects a vendor user in contact email.
        3.1. User already has the global vendor added.
        3.1. User does not have the global vendor added.
    */
    const makeCompanyVendorPaylod = ({ vendor, externalVendorId }): CreateVendorContactRequest['vendor'] => {
      // If user selected a global vendor user in the contact email field
      if (vendorUser) {
        const companyVendorsVendorsIds = companyVendorStore.companyVendorsSelect
          .filter((companyVendor) => !!companyVendor?.vendor)
          .map((companyVendor) => companyVendor?.vendor?.id)

        // If the contractor already has this vendor added
        if (companyVendorsVendorsIds.includes(vendorUser?.vendor.id)) {
          const companyVendor = companyVendorStore.companyVendorsSelect.find(
            (companyVendor) => companyVendor?.vendor?.id === vendorUser?.vendor.id,
          )
          return {
            id: companyVendor?.id,
          }
        } else {
          return {
            vendor_id: vendorUser?.vendor?.id,
          }
        }
      }

      // If user selected vendor in the vendor field
      if (isExistingVendor) {
        if (vendor?.isGlobal && !vendor?.ownedByContractor) {
          return {
            vendor_id: vendor?.vendorId,
          }
        } else {
          return {
            id: vendor?.value,
          }
        }
      }

      // If are a new vendor
      return {
        vendor_name: vendor,
        external_vendor_id: externalVendorId,
      }
    }

    const makeCompanyVendorContact = (contact): CreateVendorContactRequest['contact'] => {
      const { first_name, last_name, email, phone_number, nickname } = contact
      if (!vendorUser) {
        return {
          first_name,
          last_name,
          email,
          phone_number,
          nickname,
        }
      }

      return {
        vendor_user_id: vendorUser?.id,
      }
    }

    const handleSubmit = async (isAddAnother = false) => {
      setSubmitting(true)

      try {
        const { vendor, contact, external_vendor_id } = await form.validateFields()
        const companyVendor = makeCompanyVendorPaylod({ vendor, externalVendorId: external_vendor_id })
        const companyVendorContact = makeCompanyVendorContact(contact)

        const newVendorContact = await companyVendorStore.createVendorContact(companyVendor, companyVendorContact)

        Promise.all([
          companyVendorStore.getAllCompanyVendorsToSelect(),
          vendorStore.getAllVendors(),
          vendorStore.getAllVendorsUsers(),
          companyVendorStore.getAllCompanyVendors(onlyActives),
        ])

        if (isAddAnother) {
          resetComponent()
        } else {
          handleCancel()
        }

        if (!hideNotification) {
          message.success('Successfully created vendor contact')
        }

        onFinish?.(newVendorContact)
      } catch (error) {
        setAlertMessage(error?.response?.data?.error || 'Unable to create new vendor contact')
        noticeError(error, {
          entry: 'create-vendor-contact',
          data: form.getFieldsValue(),
        })
      } finally {
        setSubmitting(false)
      }
    }

    const defaultDomain = React.useMemo(() => {
      if (selectedCompanyVendorId) {
        const companyVendor = companyVendorStore.companyVendorsSelect?.find(
          (companyVendor) => companyVendor.id === selectedCompanyVendorId,
        )

        return companyVendor?.vendor?.domain || companyVendor?.domain
      } else if (selectedVendorId) {
        const vendor = vendorStore.vendors?.find((vendor) => vendor?.id === selectedVendorId)
        return vendor?.domain
      }
    }, [
      selectedCompanyVendorId,
      selectedVendorId,
      companyVendorStore.companyVendors.length,
      vendorStore.vendorUsers.length,
    ])

    const children = (
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onValuesChange={(formValues) => {
          if (Object.prototype.hasOwnProperty.call(formValues?.contact || {}, 'email')) {
            const email = formValues.contact.email.toLowerCase().trim()

            if (email && vendorUser && email !== vendorUser?.email) {
              vendorUser && setVendorUser(null)
              matchVendorBydomain && setMatchVendorByDomain(null)
              contactDomain && setContactDomain('')
              form.setFieldsValue({ vendor: null })
            }

            if (email) {
              const domain = email.split('@').pop()

              if (domain && !selectedCompanyVendorId) {
                setContactDomain(domain)
              }
            }

            form.setFieldsValue({ contact: { email } })
          }

          if (formValues?.vendor) {
            setMatchVendorByDomain(null)
            setContactDomain('')
          }
        }}
        style={{
          padding: 16,
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowY: 'auto',
        }}
      >
        <Box width="100%" display="flex" flexDirection="column" alignItems="inherit" justifyContent="flex-start">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: 16, width: '100%' }} />}

          <Form.Item
            name={['contact', 'email']}
            label="Email"
            validateTrigger="onBlur"
            rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}
            extra={
              vendorUser && (
                <Alert
                  type="success"
                  message={`Awesome! ${vendorUser?.full_name} is already on SubBase!`}
                  style={{ margin: '8px 0' }}
                />
              )
            }
          >
            <CompanyVendorContactAutoComplete
              onSelect={(_, option) => setVendorUser(option?.vendorUser)}
              domain={defaultDomain}
              autoFocus
            />
          </Form.Item>

          {vendorUser ? (
            <GlobalizedVendorCard
              vendorName={vendorUser?.vendor?.name}
              vendorDomain={vendorUser?.vendor?.domain}
              vendorDescription={vendorUser?.vendor?.description}
              vendorLogo={vendorUser?.vendor?.logo?.url}
              contactName={vendorUser.full_name}
              contactEmail={vendorUser?.email}
              contactHeadline={vendorUser?.headline}
            />
          ) : (
            <>
              <Row gutter={24}>
                <Col xs={24} sm={12}>
                  <Form.Item name={['contact', 'first_name']} label="First Name">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item name={['contact', 'last_name']} label="Last Name">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['contact', 'nickname']}
                    label="Nickname"
                    tooltip="You can add a nickname to the contact and it will appear in all places right after the contact name, like: [First Name] [Last Name] ([Nickname])"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item name={['contact', 'phone_number']} label="Phone Number">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                flexGrow={0}
                mb="24px"
              >
                <Typography.Title level={4}>Vendor</Typography.Title>

                <Radio.Group
                  buttonStyle="solid"
                  value={isExistingVendor}
                  onChange={() => {
                    form.setFieldsValue({ vendor: null })
                    setMatchVendorByDomain(null)
                    setExistingVendor((prev) => !prev)
                  }}
                  disabled={!!selectedCompanyVendorId || !!selectedVendorId}
                >
                  <Radio.Button value={true}>Existing Vendor</Radio.Button>
                  <Radio.Button value={false}>New vendor</Radio.Button>
                </Radio.Group>
              </Box>

              <Form.Item
                name={['vendor']}
                label="Vendor"
                rules={[{ required: true, message: 'Vendor is required!' }]}
                extra={
                  matchVendorBydomain &&
                  isExistingVendor && (
                    <Alert
                      type="success"
                      message={`Awesome! ${matchVendorBydomain?.label} is already on Subbase!`}
                      style={{ marginTop: 8 }}
                    />
                  )
                }
              >
                {isExistingVendor ? (
                  <CompanyVendorSelector
                    domain={contactDomain}
                    onMatchDomain={setMatchVendorByDomain}
                    defaultCompanyVendorId={selectedCompanyVendorId}
                    defaultVendorId={selectedVendorId}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>

              {!isExistingVendor && (
                <Form.Item
                  name="external_vendor_id"
                  label="Vendor ID"
                  tooltip="Enter the name as it appears in your accounting system."
                >
                  <Input />
                </Form.Item>
              )}
            </>
          )}
        </Box>

        <Box>
          <Divider />

          <Box display="flex" justifyContent="space-between" flexGrow={0}>
            <Button onClick={handleCancel} loading={isSubmitting} style={{ width: 100 }}>
              Cancel
            </Button>
            <Space size="middle">
              <Visibility.Hidden>
                {!hideShowSaveAndAddAnotherButton && (
                  <Button type="primary" ghost onClick={() => handleSubmit(true)} loading={isSubmitting}>
                    Save and Add Another
                  </Button>
                )}
              </Visibility.Hidden>

              <Button type="primary" onClick={() => handleSubmit()} loading={isSubmitting} style={{ width: 100 }}>
                Save
              </Button>
            </Space>
          </Box>
        </Box>
      </Form>
    )

    if (withDrawer) {
      return (
        <Drawer
          ref={ref}
          bgGray
          onClose={handleCancel}
          title={
            !hideTitle && (
              <Typography.Title level={5} style={{ margin: 0 }}>
                New Vendor Contact
              </Typography.Title>
            )
          }
        >
          {children}
        </Drawer>
      )
    }

    return children
  },
  { forwardRef: true },
)
