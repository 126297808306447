/* eslint-disable @typescript-eslint/no-namespace */
import axios from 'axios'

import { Address } from 'common/server/addresses'
import { AddressTo } from 'common/server/addressesTo'
import { OrderMaterial } from 'common/server/orders'
import {
  DeliveryIssueTypes,
  DeliveryStates,
  DeliveryStatuses,
  File,
  OrderStates,
  SearchKeysResponse,
} from 'common/server/server_types'

export interface DeliveryHit {
  objectID: string
  id: string
  created_at: string
  updated_at: string
  state: string
  requested_delivered_at: string
  marked_shipped_at: string
  shipped_at: string
  actual_delivered_at: string
  marked_delivered_at: string
  carrier: string
  tracking_number: string
  estimated_delivered_at: string
  delivery_issue_type: string
  delivery_issues: string
  has_open_issue: boolean
  delivery_request_difference_hours: number
  order: { id: string; order_number: string; confirmed_at: string }
  order_name: string
  project_name: string
  address: { id: string; nickname: string; address_line1: string }
  company_vendor: { id: string; vendor_name: string }
  company_vendor_name: string
  company_id: string
  company: { id: string; name: string }
  best_guess_delivered_at: string
  is_pick_up: boolean
}

export interface ShowDeliveryResponse {
  order: {
    id: string
    project_id: string
    order_package_name: string
    vendor_name: string
    state: OrderStates
    order_number: string
    order_materials: OrderMaterial[]
  }
  delivery: OrderDelivery
}

export interface OrderDelivery {
  id?: string
  order_id: string
  state: string
  // Request
  address: Address
  address_to?: AddressTo
  requested_delivered_at: string
  instructions: string
  auto_follow_up_days?: number // Not yet implemented
  lead_time_days: number
  // Shipping
  marked_shipped_at?: string
  shipped_at: string
  carrier: string
  shipping_carrier_id: string
  tracking_number: string
  estimated_delivered_at: string
  actual_delivered_at: string
  // Receiving
  incomplete_items: OrderMaterial[]
  marked_delivered_at?: string
  marked_delivered_by?: { id: string; first_name: string; last_name: string; email: string }
  delivery_issues: string
  delivery_issue_type: DeliveryIssueTypes | DeliveryStatuses
  delivery_issue_resolved_at?: string
  delivery_issue_resolved_by?: { id: string; first_name: string; last_name: string; email: string }
  has_open_issue: boolean
  delivery_files: File[]
  delivery_files_signed_ids: string[]
  delivery_files_delete_ids: string[]
  silent_update?: boolean
  tax_value?: number
  discount_value?: number
  shipping_value?: number
  other_value?: number
  is_pick_up: boolean
  best_guess_delivered_at?: string
  delivery_charges_units?: DeliveryChargesUnit
  generate_purchase_order_pdf?: boolean

  // The field used only in the front end to check if the delivery changed
  updated?: boolean
}

export type ChargeType = 'decimal' | 'percentage'

export interface DeliveryChargesUnit {
  discount_value: ChargeType
  shipping_value: ChargeType
  other_value: ChargeType
  tax_value: ChargeType
}

export namespace Deliveries {
  export interface Index {
    id: string
    state: DeliveryStates
    project_id: string
    project_name: string
    order_id: string
    order_name: string
    order_number?: string
    company_vendor_name?: string
    best_guess_delivered_at?: string
    delivery_issue_type?: DeliveryIssueTypes
    is_pick_up: boolean
    has_open_issue: boolean
    requested_delivered_at?: string
    estimated_delivered_at?: string
    actual_delivered_at?: string
  }

  export namespace Queries {
    export interface Index {
      ordered?: boolean
      not_of_state?: DeliveryStates
      project_active?: boolean
      best_guess_delivered_at_start?: Date
      best_guess_delivered_at_end?: Date
      project_id?: string
      page?: number
      per_page?: number
      has_open_issue?: boolean
      order_state?: OrderStates[]
      sort_by?: string
      sort_order?: 'asc' | 'desc'
    }
  }

  export namespace Responses {
    export interface Index {
      records: Deliveries.Index[]
      total_count: number
      has_more: boolean
    }
  }
}

export function search_key() {
  return axios.get<SearchKeysResponse>('/deliveries/search_key')
}

export async function index(params: Deliveries.Queries.Index): Promise<Deliveries.Responses.Index> {
  const response = await axios.get<Deliveries.Responses.Index>('/deliveries', {
    params,
  })

  return response.data
}

export function show(id: string) {
  return axios.get<ShowDeliveryResponse>(`/deliveries/${id}`)
}

export function update(payload: OrderDelivery) {
  return axios.patch<ShowDeliveryResponse>(`/deliveries/${payload['id']}`, payload)
}
