export interface SearchParamsResponse {
  total_count: number
  limit: number
  offset: number
}

export interface WebSocketMessage<T = never> {
  type: string
  entity_name: string
  entity_id: string
  data?: T
}

export interface StandardIndexRequest {
  sort?: string
  filters: { [filter_name: string]: string | string[] | boolean }
  page?: number
  per_page?: number
  search?: string
  only_discarded?: boolean
}

export interface StandardIndexResponse<RecordType> {
  records: RecordType
  total_count: number
  has_more: number
}

export interface SearchKeysResponse {
  search_key: string
  application_id: string
  index_name: string
  replica_index_name?: string
  replica_index_search_key?: string
  index_names?: string[]
  replicas?: string[]
}

export interface File {
  attachment_id: string
  key: string
  signed_id: string
  filename: string
  url: string
}

export interface VendorLogo extends File {
  variants: {
    small: string
  }
}

export type InvoiceSourceType = 'InvoiceMail' | 'InvoiceUpload'

export interface Role {
  id: string
  name: string
}

// These are manually copied over from lib/types.rb
export enum UserRoles {
  ADMIN = 'ADMIN',
  PURCHASER = 'PURCHASER',
  USER = 'USER',
  MATERIAL_REQUESTER = 'MATERIAL_REQUESTER',
}

export enum UserTiers {
  BASIC = 'BASIC',
  PROFESSIONAL = 'PROFESSIONAL',
  BUILDER = 'BUILDER',
}

export enum LockOrderOptionType {
  NEVER = 'NEVER',
  DELIVERY_COMPLETION = 'DELIVERY_COMPLETION',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  ORDER_INVOICED = 'ORDER_INVOICED',
}

export enum VendorUserRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum VendorUserTiers {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
}

export enum CompanyProjectRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum CompanyTypes {
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
  SUB_CONTRACTOR = 'SUB_CONTRACTOR',
  CONSTRUCTION_MANAGER = 'CONSTRUCTION_MANAGER',
}

export enum NotificationTypes {
  ORDER_UPDATE = 'ORDER_UPDATE',
  INVOICE_ASSIGN = 'INVOICE_ASSIGN',
  INTERNAL_MESSAGE = 'INTERNAL_MESSAGE',
}

export enum DeliveryIssueTypes {
  INCORRECT_ITEMS = 'INCORRECT_ITEMS',
  MISSING_ITEMS = 'MISSING_ITEMS',
  BROKEN_ITEMS = 'BROKEN_ITEMS',
  OTHER = 'OTHER',
}

export enum DeliveryStatuses {
  COMPLETE = 'COMPLETE',
  INCOMPLETE_DELIVERY = 'INCOMPLETE_DELIVERY',
}

export enum CarrierTypes {
  INCORRECT_ITEMS = 'INCORRECT_ITEMS',
  MISSING_ITEMS = 'MISSING_ITEMS',
  BROKEN_ITEMS = 'BROKEN_ITEMS',
  OTHER = 'OTHER',
}

export enum ColumnEditableByTypes {
  VENDOR_ONLY = 'VENDOR_ONLY',
  PURCHASER_ONLY = 'PURCHASER_ONLY',
  EVERYONE = 'EVERYONE',
}
// ################################ STATES ################################

export enum OrderStates {
  REQUESTED = 'REQUESTED',
  DRAFT = 'DRAFT',
  DRAFTED_REQUEST = 'DRAFTED_REQUEST',
  APPROVED = 'APPROVED',
  QUOTED = 'QUOTED',
  ORDERED = 'ORDERED',
  SHIPPED = 'SHIPPED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  ORDERED_CONFIRMED_CANCELLATION_REQUESTED = 'ORDERED_CONFIRMED_CANCELLATION_REQUESTED',
}

export enum OrderStatesFilter {
  DRAFT = 'DRAFT',
  DRAFTED_REQUEST = 'DRAFTED_REQUEST',
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  QUOTED = 'QUOTED',
  ORDERED = 'ORDERED',
  SHIPPED = 'SHIPPED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum OrderSubStates {
  DRAFT_QUOTE = 'DRAFT_QUOTE',
  DRAFT_ORDER = 'DRAFT_ORDER',
  DRAFTED_REQUEST = 'DRAFTED_REQUEST',
  APPROVED = 'APPROVED',
  REQUESTED_QUOTE = 'REQUESTED_QUOTE',
  REQUESTED_ORDER = 'REQUESTED_ORDER',
  QUOTED_REQUESTED = 'QUOTED_REQUESTED',
  QUOTED_RECEIVED = 'QUOTED_RECEIVED',
  ORDERED_REQUESTED = 'ORDERED_REQUESTED',
  ORDERED_CONFIRMED = 'ORDERED_CONFIRMED',
  CANCELLED_QUOTE_BY_VENDOR = 'CANCELLED_QUOTE_BY_VENDOR',
  CANCELLED_ORDER_BY_VENDOR = 'CANCELLED_ORDER_BY_VENDOR',
  CANCELLED_QUOTE_BY_BUYER = 'CANCELLED_QUOTE_BY_BUYER',
  CANCELLED_ORDER_BY_BUYER = 'CANCELLED_ORDER_BY_BUYER',
  CANCELLED_MERGED = 'CANCELLED_MERGED',
  DELETED_DRAFT = 'DELETED_DRAFT',
  DELETED_REQUEST = 'DELETED_REQUEST',
}

export enum CancelledQuoteExpired {
  CANCELLED_QUOTED_EXPIRED = 'QUOTED_EXPIRED',
}

export enum OrderMaterialStates {
  NONE = 'NONE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum DeliveryStates {
  UNSCHEDULED = 'UNSCHEDULED',
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
}

export enum NotificationFrequency {
  IMMEDIATELY = 'Email notifications as they happen',
  ONCE_PER_HOUR = 'Hourly notifications',
  ONCE_PER_DAY = 'Daily notifications',
  DISABLED = 'Email notifications OFF',
}

export enum HomePageOptions {
  PROJECTS = 'Projects',
  ORDERS = 'Orders',
  INVOICES = 'Invoices',
}

export enum OrderAuditLogStates {
  NO_FILES_UPLOADED = 'NO_FILES_UPLOADED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  COMPLETE = 'COMPLETE',
}

export enum InvoiceInboxStates {
  RECEIVED = 'RECEIVED',
  EXTRACTING = 'EXTRACTING',
  TRANSFORMING = 'TRANSFORMING',
  TRANSFORMATION_COMPLETED = 'TRANSFORMATION_COMPLETED',
}

export enum InvoiceInboxIssueActionType {
  REPORTED = 'REPORTED',
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
  FAILED_DUE_TO_UPDATE = 'FAILED_DUE_TO_UPDATE',
}

export enum QuoteExtractionStates {
  EXTRACTING = 'EXTRACTING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NOT_EXTRACTED = 'NOT_EXTRACTED',
}

export enum InvoiceInboxFilesStates {
  SUCCESS = 'SUCCESS',
  EXTRACTING = 'EXTRACTING',
  FAILED = 'FAILED',
  IN_REVIEW = 'IN_REVIEW',
  RESOLVED = 'RESOLVED',
  AUTO_IGNORED = 'AUTO_IGNORED',
  NONE = 'NONE',
}

export enum RfqExtractionStates {
  NOT_EXTRACTED = 'NOT_EXTRACTED',
  EXTRACTING = 'EXTRACTING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum InvoiceInboxRibbonStates {
  EXTRACTING = 'EXTRACTING',
  FAILED = 'FAILED',
  CREATED = 'CREATED',
  IGNORED = 'IGNORED',
  IN_REVIEW = 'IN_REVIEW',
  TRANSFORMATION_COMPLETED = 'TRANSFORMATION_COMPLETED',
}

export enum InvoicesStates {
  UNREVIEWED = 'UNREVIEWED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  POSTED = 'POSTED',
}

export enum CommitmentStatuses {
  DRAFT = 'DRAFTED',
  CONFIRMED = 'CONFIRMED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum InvoicesStatuses {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED = 'PUBLISHED',
  CORRELATING_ITEMS = 'CORRELATING_ITEMS',
  CORRELATION_DONE = 'CORRELATION_DONE',
  CORRELATION_FAILED = 'CORRELATION_FAILED',
}

export enum InvoiceInboxSubStates {
  BY_EMAIL = 'BY_EMAIL',
  BY_API = 'BY_API',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  IN_REVIEW = 'IN_REVIEW',
  FINISHED = 'FINISHED',
  IGNORED = 'IGNORED',
  AUTO_IGNORED = 'AUTO_IGNORED',
  RESOLVED = 'RESOLVED',
  NO_ACTION = 'NO_ACTION',
}

export enum MaterialRequestTypes {
  ORDER = 'ORDER',
  RFQ = 'RFQ',
}

export enum PONumberGenerationMethods {
  DEFAULT = 'DEFAULT',
  ORDER_NUMBER__COMPANY_ID = 'ORDER_NUMBER__COMPANY_ID',
  ORDER_NUMBER__PROJECT_ID = 'ORDER_NUMBER__PROJECT_ID',
  ORDER_NUMBER__PROJECT_ID__TCM = 'ORDER_NUMBER__PROJECT_ID__TCM',
  PREFIX_WITH_PROJECT_ID = 'PREFIX_WITH_PROJECT_ID',
}

export enum VendorResponseType {
  SUBSTITUTION = 'SUBSTITUTION',
  DO_NOT_CARRY = 'DO_NOT_CARRY',
  CHANGED_UNITS = 'CHANGED_UNITS',
  CHANGED_PRICE = 'CHANGED_PRICE',
  BACKORDERED = 'BACKORDERED',
  DID_NOT_QUOTE = 'DID_NOT_QUOTE',
  OTHER = 'OTHER',
}

export enum IntegrationsSourceSystem {
  PROCORE = 'procore',
  QBD = 'quick-books-desktop',
  QBO = 'quick-books-online',
  FOUNDATION_HOSTED = 'foundation-hosted',
  CMIC = 'cmic',
  SPECTRUM = 'spectrum',
  ACUMATICA = 'acumatica',
}

export enum RelationshipStockStatus {
  NOT_SYNCED = 'NOT_SYNCED',
  SYNCING = 'SYNCING',
  FAILED = 'FAILED',
  SYNCED = 'SYNCED',
}

export enum IntegrationsEntities {
  ITEM = 'item',
  VENDOR = 'vendor',
  PROJECT = 'project',
  CUSTOMER = 'customer',
  COST_CODE = 'cost_code',
  COST_TYPE = 'cost_type',
  SUB_JOB = 'sub_job',
  LEDGER_ACCOUNT = 'ledger_account',
  PURCHASE_ORDER = 'purchase_order',
  BILL = 'bill',
}

export enum MappingDirections {
  INSIDE_OUT = 'inside_out',
  OUTSIDE_IN = 'outside_in',
}

export enum InvoiceDiscardReasons {
  MISTAKE_ON_CREATION = 'MISTAKE_ON_CREATION',
  DUPLICATED = 'DUPLICATED',
  CANCELLED = 'CANCELLED',
  OUTDATED = 'OUTDATED',
  INCORRECT = 'INCORRECT',
  NOT_AN_INVOICE = 'NOT_AN_INVOICE',
  EXTRACTION_PROBLEM = 'EXTRACTION_PROBLEM',
  OTHER = 'OTHER',
}

export enum DeliveryFileNameConvention {
  CURRENT_DATE_TIME = 'CURRENT_DATE_TIME',
  ORIGINAL_FILE_NAME = 'ORIGINAL_FILE_NAME',
}

export enum IntegrationTaxBehavior {
  LINE_ITEM_TAX_AMOUNT = 'LINE_ITEM_TAX_AMOUNT',
  TAX_AS_LINE_ITEM = 'TAX_AS_LINE_ITEM',
  GENERAL_TAX = 'GENERAL_TAX',
}
