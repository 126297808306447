import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Form, FormInstance } from 'antd'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/constants'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { CompanyMaterialVendorPrice } from 'common/server/company_materials'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment } from 'contractor/server/commitments'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

const NewQuoteContext = createContext<NewQuoteContextProps>({} as NewQuoteContextProps)

export const useNewQuote = () => useContext(NewQuoteContext)

export interface CompanyVendor {
  id: string
  vendor_name: string
  vendor: CompanyVendorContact['company_vendor']['vendor']
  contacts: CompanyVendorContact['contact'][]
  comment?: string
}

type NewQuoteContextProps = {
  isSameDefaultVendorsMaterialsAndOrderVendor: boolean
  showDefaultVendor: boolean
  toggleShowDefaultVendor: React.Dispatch<React.SetStateAction<boolean>>
  companyVendors: CompanyVendor[]
  setCompanyVendors: React.Dispatch<React.SetStateAction<CompanyVendor[]>>
  syncOrderWithIntegration: boolean
  setSyncOrderWithIntegration: React.Dispatch<React.SetStateAction<boolean>>
  orderNumber: string
  setOrderNumber: React.Dispatch<React.SetStateAction<string>>
  projectId: string
  handleDefaultVendorsPricesApplied: (params?: { index?: number; defaultVendor?: CompanyMaterialVendorPrice }) => void
  termsAndConditionId: string
  setTermsAndConditionId: React.Dispatch<React.SetStateAction<string>>
  commitment: Commitment
  loadCommitment: (commitmentId: string) => void
  setCommitment: React.Dispatch<React.SetStateAction<Commitment>>
  form?: FormInstance
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const NewQuoteProvider = observer(({ children }) => {
  const {
    orderStore,
    projectStore,
    companySettingStore,
    unitsStore,
    costCodeStore,
    companyVendorStore,
    addressStore,
    integrationStore,
    vendorStore,
    userStore,
    commitmentStore,
  } = useStores()

  const location = useLocation()
  const { listStore } = commitmentStore

  const [isSpreadsheetMode, toggleSpreadsheetMode] = useState(false)
  const [showDefaultVendor, toggleShowDefaultVendor] = useState(false)

  const [companyVendors, setCompanyVendors] = useState<CompanyVendor[]>([])
  const [commitment, setCommitment] = useState<Commitment>()
  const [defaultVendorsPricesApplied, setDefaultVendorsPricesApplied] = useState<
    {
      index: number
      defaultVendorId: string
    }[]
  >([])

  const [syncOrderWithIntegration, setSyncOrderWithIntegration] = useState(
    localStorage.getItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY) === 'true',
  )

  const [orderNumber, setOrderNumber] = useState<string>()
  const [termsAndConditionId, setTermsAndConditionId] = useState<string>()
  const [form] = Form.useForm()
  const projectId = Form.useWatch('projectId', form)

  const orderVendorsIds = companyVendors.map((companyVendor) => companyVendor?.id)
  const commitmentId = Form.useWatch('commitmentId', form)

  const isSameDefaultVendorsMaterialsAndOrderVendor = useMemo(() => {
    if (!orderVendorsIds.length || !defaultVendorsPricesApplied.length) {
      return true
    }

    const defaultVendorsIds = defaultVendorsPricesApplied
      .filter((defaultVendor) => !!defaultVendor.defaultVendorId)
      .map((defaultVendor) => defaultVendor.defaultVendorId)

    return defaultVendorsIds.every((defaultVendorsId) => orderVendorsIds.includes(defaultVendorsId))
  }, [orderVendorsIds, defaultVendorsPricesApplied])

  const handleDefaultVendorsPricesApplied = useCallback(
    ({ index, defaultVendor }) => {
      const matchedDefaultVendorPriceApplied = defaultVendorsPricesApplied.some(
        (defaultVendorPriceApplied) => defaultVendorPriceApplied.index === index,
      )

      if (matchedDefaultVendorPriceApplied) {
        const changes = defaultVendorsPricesApplied.map((defaultVendorPriceApplied) => {
          if (defaultVendorPriceApplied.index === index) {
            return {
              ...defaultVendorPriceApplied,
              defaultVendorId: defaultVendor?.company_vendor?.id,
            }
          }

          return defaultVendorPriceApplied
        })

        setDefaultVendorsPricesApplied(changes)
      } else {
        setDefaultVendorsPricesApplied((prevState) => [
          ...prevState,
          { index, defaultVendorId: defaultVendor?.company_vendor?.id },
        ])
      }
    },
    [defaultVendorsPricesApplied.length],
  )

  const loadCommitment = useCallback(async (commitmentId: string) => {
    const commitment = await commitmentStore.loadCommitment(commitmentId)
    setCommitment(commitment)
    form.setFieldValue('commitmentId', commitment.id)
  }, [])

  useEffect(() => {
    if (commitment && companyVendorStore?.vendorContacts?.length > 0) {
      const foundContacts = companyVendorStore?.vendorContacts
        ?.filter((contact) => contact.company_vendor.id === commitment?.company_vendor_id)
        ?.map((contact) => {
          return {
            addresses: contact.company_vendor.addresses,
            contacts: [contact.contact],
            vendor_name: contact.company_vendor?.vendor_name || contact.company_vendor?.vendor?.name,
            id: contact.company_vendor.id,
          }
        })

      if (foundContacts?.length == 1) {
        setCompanyVendors(foundContacts)
      }
    }
  }, [commitment?.id, companyVendorStore?.vendorContacts])

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.maybeIndexProjects(),
      orderStore.getOrderSettings(), // For Tags
      companySettingStore.indexCompanyMaterialConfiguration(),
      companySettingStore.indexOtherSettings(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeStore.costCodePhaseListStore.fetchRecords(),
      companyVendorStore.getAllCompanyVendors(true),
      addressStore.maybeIndexAddresses(),
      unitsStore.maybeUnits(),
      companyVendorStore.getAllCompanyVendorContacts(),
      listStore?.fetchRecords(),
    ])
  })
  useQuery(() => Promise.all([companyVendorStore.getAllCompanyVendors(true), vendorStore.getAllVendors()]))
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useEffect(() => {
    orderStore.endToSplitOrder()
    // If a project id was passed in, load it now
    const projectIdParam = new URLSearchParams(location.search).get('project_id')
    if (projectIdParam) {
      projectStore.showProject(projectIdParam)
    }
  }, [location.search])

  useEffect(() => {
    // If the user clears the commitment field, we should clear the selected commitment too

    if (commitmentId) {
      loadCommitment(commitmentId)
    } else {
      setCommitment(undefined)
    }
  }, [commitmentId, loadCommitment])

  if (isLoading) {
    return <Loading />
  }

  return (
    <NewQuoteContext.Provider
      value={{
        isSameDefaultVendorsMaterialsAndOrderVendor,
        showDefaultVendor,
        toggleShowDefaultVendor,
        companyVendors,
        setCompanyVendors,
        syncOrderWithIntegration,
        setSyncOrderWithIntegration,
        orderNumber,
        setOrderNumber,
        projectId,
        handleDefaultVendorsPricesApplied,
        termsAndConditionId,
        setTermsAndConditionId,
        commitment,
        loadCommitment,
        setCommitment,
        form,
        isSpreadsheetMode,
        toggleSpreadsheetMode,
      }}
    >
      {children}
    </NewQuoteContext.Provider>
  )
})

export const withNewQuoteProvider = (Component) => (props) => {
  return (
    <NewQuoteProvider>
      <Component {...props} />
    </NewQuoteProvider>
  )
}
