import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import styled from '@emotion/styled'

import { FileDoneOutlined, ShoppingCartOutlined, SnippetsOutlined } from '@ant-design/icons'
import { Badge, Button, Tabs } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'

import { useCommitment } from 'contractor/pages/@v2/Commitments/Components/CommitmentContext/context'
import { CommitmentsInvoices } from 'contractor/pages/@v2/Commitments/Components/CommitmentInvoices/commitment_invoices'
import { CommitmentsMaterials } from 'contractor/pages/@v2/Commitments/Components/CommitmentMaterials/index'
import { CommitmentsOrders } from 'contractor/pages/@v2/Commitments/Components/CommitmentOrders/commitment_orders'

const insertIf = (condition, ...elements) => (condition ? elements : [])

type AttachmentsProps = {
  disabled?: boolean
}

const StyledCard = styled(Box)`
  padding: 8px;
  margin: 0;
  border-width: 0 1px 1px 1px !important;
  border-style: none solid solid solid !important;
  border-color: transparent #f0f0f0 #f0f0f0 #f0f0f0 !important;
  background-color: white;
  border-radius: 4px;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 !important;
  }
`

export const MaterialsAndOrders = (props: AttachmentsProps) => {
  const { disabled } = props
  const history = useHistory()
  const location = useLocation()
  const { commitment } = useCommitment()
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false)
  const isCreated = commitment?.created_at
  const source = window.location.pathname

  const handleOrderDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible)
  }

  const activeKey = new URLSearchParams(location.search).get('commitment_tab')
  const showAddOrder = activeKey === 'orders'
  const tabItems = [
    {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.commitment_materials?.length} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_COMMITMENTS_TAB, source)} paddingX="8">
            <ShoppingCartOutlined />
            Materials
          </Box>
        </Badge>
      ),
      key: 'materials',
      children: (
        <StyledCard>
          <CommitmentsMaterials disabled={disabled} />
        </StyledCard>
      ),
    },
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.order_count} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_ORDERS_TAB, source)} paddingX="8">
            <FileDoneOutlined />
            Orders
          </Box>
        </Badge>
      ),
      key: 'orders',
      children: (
        <StyledCard>
          <CommitmentsOrders closeOrderDrawer={handleOrderDrawer} isOrderDrawerVisible={isDrawerVisible} />
        </StyledCard>
      ),
    }),
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.invoice_count} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_INVOICES_TAB, source)} paddingX="8">
            <SnippetsOutlined />
            Invoices
          </Box>
        </Badge>
      ),
      key: 'invoices',
      children: (
        <StyledCard>
          <CommitmentsInvoices />
        </StyledCard>
      ),
    }),
  ]

  return (
    <StyledTabs
      type="card"
      tabBarExtraContent={showAddOrder && <Button onClick={handleOrderDrawer}>Add order</Button>}
      activeKey={activeKey || 'materials'}
      onChange={(key) => {
        const searchParams = new URLSearchParams(history.location.search)
        searchParams.set('commitment_tab', key)
        history.push({ search: searchParams.toString() })
      }}
      items={tabItems}
    />
  )
}
