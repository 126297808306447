import React, { useState, useEffect } from 'react'

import { omit } from 'lodash'

import { Button, Checkbox, Input, Divider, Row, Col, Alert, Form, Space, Typography } from 'antd'
import { AlertProps } from 'antd/lib/alert'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { makeCostCodeValue } from 'common/components/SelectCostCode'
import { makeOption as makeUnitOption } from 'common/components/SelectUnit'
import Tags from 'common/components/tags'
import { formatEnumValue } from 'common/helpers/formatters'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'

import { makeCostCodePhaseOption } from 'contractor/components/SelectCostCodePhase'
import { useNotifyCompanyMaterial } from 'contractor/hooks/use-notify-company-material'
import { useStores } from 'contractor/hooks/use-stores'

import { getFormUnit } from '../create_material'
import { FieldCompanyVendors } from '../Fields/field_company_vendors'
import { FieldCostCode } from '../Fields/field_cost_code'
import { FieldCostCodePhase } from '../Fields/field_cost_code_phase'
import { FieldDescription } from '../Fields/field_description'
import { FieldImageUrl } from '../Fields/field_image_url'
import { FieldProjectEspecific } from '../Fields/field_project_especific'
import { FieldUnit } from '../Fields/field_unit'
import { getErrorMessages } from '../utils'
import { ModalUpdateOrCreateMaterial } from './modal_update_or_create_material'
import { UpdateMaterialProps } from './update_material'

type EditableDataProps = { isManagedBySubBase: boolean } & UpdateMaterialProps

const alertCommonProps = {
  closable: true,
  style: { width: '100%', marginBottom: 12 },
} as AlertProps

const getInitialUnit = (companyMaterial) => {
  if (companyMaterial?.unit_id) {
    return makeUnitOption(companyMaterial?.unit)
  }

  if (companyMaterial?.unit_name) {
    return { value: companyMaterial?.unit_name, label: companyMaterial?.unit_name }
  }
}

export const EditableData = observer<EditableDataProps>((props) => {
  const { editable, onSubmit, onCancel, onDuplicate, isManagedBySubBase, hideNotification, scope } = props

  const { companyMaterialStore, companySettingStore, userStore, unitsStore } = useStores()

  const companyMaterial = companyMaterialStore.selectedCompanyMaterial
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const { notifyCompanyVendor } = useNotifyCompanyMaterial({ operation: 'update' })

  const [form] = Form.useForm()
  const imageUrlField = Form.useWatch('image_url', form)

  const [alertMessage, setAlertMessage] = useState('')
  const [changeCostCodeId, setChangedCostCodeId] = useState()
  const [isSubmitting, setSubmitting] = useState(false)
  const [showModalUpdateOrCreate, setShowModalUpdateOrCreate] = useState(false)

  const handleUpdateMaterial = async (formValues) => {
    showModalUpdateOrCreate && setShowModalUpdateOrCreate(false)
    setSubmitting(true)

    try {
      const originalMaterial = { ...companyMaterialStore.selectedCompanyMaterial }
      const companyMaterialVendorPricesattributes = formValues?.company_material_vendor_prices_attributes || []
      const itemsToUpdateWithDestroy = companyMaterial?.company_material_vendor_prices
        ?.filter((originalItem) =>
          companyMaterialVendorPricesattributes?.every((currentItem) => currentItem.id != originalItem.id),
        )
        .map((item) => ({
          ...item,
          _destroy: true,
        }))

      // If is catalog material, the user can edit only the active, unit, cost code, company vendor and default price
      const data = isManagedBySubBase
        ? {
            tags: formValues?.tags,
            active: formValues?.active,
            cost_code_id: formValues?.cost_code_id || null,
            cost_code_phase_id: formValues?.cost_code_phase_id || null,
            project_ids: formValues?.project_ids,
            unit_id: formValues?.unit_id,
            unit_name: formValues?.unit_name,
            company_material_vendor_prices_attributes:
              companyMaterialVendorPricesattributes.concat(itemsToUpdateWithDestroy),
          }
        : {
            ...formValues,
            company_material_vendor_prices_attributes:
              companyMaterialVendorPricesattributes.concat(itemsToUpdateWithDestroy),
          }

      const response = await companyMaterialStore.update({
        ...data,
        id: companyMaterial?.id,
      })

      if (!hideNotification) {
        notifyCompanyVendor({ companyMaterialId: companyMaterial?.id })
      }
      companyMaterialStore.selectMaterial(null)
      setAlertMessage('')
      onSubmit?.(response, originalMaterial)
    } catch (error) {
      console.log({ error })
      const errorMessage = getErrorMessages(error)
      setAlertMessage(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }

  /*
    We allow users to update material on the order page even if the material has not been created yet.
    So if the material has not been created yet we just return new form changes
  */
  const handleFormFinish = (formValues) => {
    const originalMaterial = { ...companyMaterialStore.selectedCompanyMaterial }
    const updatedCompanyMaterial = omit(formValues, ['cost_code', 'cost_code_phase'])
    if (companyMaterial?.id) {
      handleUpdateMaterial({
        ...updatedCompanyMaterial,
        cost_code_id: formValues?.cost_code?.value || null,
        cost_code_phase_id: formValues?.cost_code_phase?.value || null,
        ...getFormUnit(formValues),
        requested_by_id: null,
        requested_at: null,
      })
    } else {
      onSubmit?.(
        {
          ...companyMaterial,
          ...updatedCompanyMaterial,
          cost_code_id: formValues?.cost_code?.value,
          cost_code_phase_id: formValues?.cost_code_phase?.value,
          ...getFormUnit(formValues),
        },
        originalMaterial,
      )
    }
  }

  const handleCancel = () => {
    setAlertMessage('')
    form.resetFields()
    companyMaterialStore.selectMaterial(null)
    onCancel()
  }

  /*
    If the user clicks not to update material we just return new form
    changes with is_new: true and this material will be created on order flow.
  */
  const handleNotUpdate = () => {
    const originalMaterial = { ...companyMaterialStore.selectedCompanyMaterial }
    setShowModalUpdateOrCreate(false)

    form.validateFields().then((formValues) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = { ...companyMaterial, ...formValues, is_new: true } as any
      onSubmit?.(data, originalMaterial)
    })
  }

  const disabled = !editable || isManagedBySubBase

  const renderInput = (columnName) => {
    switch (columnName) {
      case 'cost_code_id':
        return (
          <>
            <Col xs={24} sm={12} key={columnName}>
              <FieldCostCode
                data-cy="material-cost-code-input"
                inputProps={{
                  disabled: !editable,
                }}
              />
            </Col>
            {companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled && (
              <Col xs={24} sm={12} key="cost_code_phase">
                <FieldCostCodePhase inputProps={{ disabled: !editable }} form={form} />
              </Col>
            )}
          </>
        )
      case 'preferred_vendor_prices':
        return (
          <Col span={24} key={columnName}>
            <FieldCompanyVendors
              preferredPrices={companyMaterial?.company_material_vendor_prices}
              data-cy="material-vendor-input"
              disabled={!editable}
              parentForm={form}
            />
          </Col>
        )
      case 'description':
        return (
          <Col xs={24} key={columnName}>
            <Box display="flex" alignItems="center" style={{ gap: 16 }}>
              <FieldDescription data-cy="material-description-input" disabled={disabled} />
              <Form.Item name="active" label="Active" valuePropName="checked">
                <Checkbox disabled={!editable} />
              </Form.Item>
            </Box>
          </Col>
        )
      case 'unit':
        return (
          <Col key={columnName} xs={24} sm={12}>
            <FieldUnit data-cy="material-unit-input" disabled={!editable} units={unitsStore.units} />
          </Col>
        )
      case 'project_ids':
        return (
          <Col key={columnName} xs={24}>
            <FieldProjectEspecific data-cy="material-projects-input" />
          </Col>
        )
      default:
        return (
          <Col key={columnName} xs={24} sm={12}>
            <Form.Item name={columnName} label={formatEnumValue(columnName)}>
              <Input data-cy={`material-${columnName}-input`} disabled={disabled} aria-autocomplete="none" />
            </Form.Item>
          </Col>
        )
    }
  }

  useEffect(() => {
    if (companyMaterial) {
      form.setFieldsValue({
        ...companyMaterial,
        unit: getInitialUnit(companyMaterial),
        cost_code: makeCostCodeValue(companyMaterial?.cost_code, costCodeSettings),
        cost_code_phase: makeCostCodePhaseOption(companyMaterial?.cost_code_phase),
        project_ids: Array.isArray(companyMaterial['project_ids']) ? companyMaterial['project_ids'] : [],
        company_material_vendor_prices_attributes: companyMaterial?.company_material_vendor_prices?.map(
          (companyMaterialVendorPrice) => ({
            id: companyMaterialVendorPrice.id,
            price: companyMaterialVendorPrice.price,
            company_vendor_id: companyMaterialVendorPrice.company_vendor?.id,
          }),
        ),
      })
    }
  }, [companyMaterial])

  const companyAttributes = React.useMemo(() => {
    const columns = companySettingStore.companyMaterialConfiguration.company_attributes || []

    const projectIdsIndex = columns.indexOf('project_ids')
    const preferredVendorPricesIndex = columns.indexOf('preferred_vendor_prices')

    let newColumns = [...columns]

    if (projectIdsIndex !== -1) {
      newColumns = newColumns.filter((item) => item !== 'project_ids')
      newColumns.push('project_ids')
    }

    if (preferredVendorPricesIndex !== -1) {
      newColumns = newColumns.filter((item) => item !== 'preferred_vendor_prices')
      newColumns.push('preferred_vendor_prices')
    }

    return newColumns
  }, [companySettingStore.companyMaterialConfiguration.company_attributes?.length])

  const previouslyOrdered = companyMaterial?.order_materials?.length > 0
  const hasChangedCostCode = changeCostCodeId && changeCostCodeId !== companyMaterial?.cost_code?.id

  const showAlertEditMaterial = previouslyOrdered && !isManagedBySubBase

  const isRequestedMaterial = !!companyMaterial?.requested_by_id

  return (
    <Form
      form={form}
      onFinish={handleFormFinish}
      layout="vertical"
      style={{ width: '100%', height: '100%' }}
      onValuesChange={(changedValues) => {
        if (changedValues && !!changedValues.cost_code) {
          if (changedValues.cost_code) {
            setChangedCostCodeId(changedValues.cost_code?.value)
          } else {
            setChangedCostCodeId(null)
          }
        }
      }}
    >
      <ModalUpdateOrCreateMaterial
        visible={showModalUpdateOrCreate}
        onCancel={() => setShowModalUpdateOrCreate(false)}
        onYes={() => form.submit()}
        onNo={handleNotUpdate}
        ordersCount={companyMaterial?.order_materials?.length}
      />

      {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

      <FlexBoxY alignItems="flex-start" width="100%" height="100%" justifyContent="space-between">
        <Row gutter={20} style={{ width: 'calc(100% + 20px)' }}>
          {companyAttributes.map(renderInput)}

          <Col span={24}>
            <Form.Item name="tags" label="Tags">
              <Tags tags={companyMaterialStore.companyMaterialTags} />
            </Form.Item>
          </Col>

          <FieldImageUrl imageUrlField={imageUrlField} />
        </Row>

        <FlexBoxY width="100%" alignItems="flex-end" mt="12px" flexGrow={0}>
          {editable && hasChangedCostCode && previouslyOrdered && !isRequestedMaterial && (
            <Alert
              message={
                <Typography.Text>
                  Changing this code will NOT modify existing orders. If you need help with that, reach out to{' '}
                  <Typography.Link href={`mailto: ${SUPPORT_EMAIL_ADDRESS}`}>{SUPPORT_EMAIL_ADDRESS}</Typography.Link>
                </Typography.Text>
              }
              type="warning"
              {...alertCommonProps}
            />
          )}

          {editable && showAlertEditMaterial && !isRequestedMaterial && (
            <Alert
              message="Editing information for materials that have been previously ordered is dangerous. You are overwriting past orders and your vendors will NOT be notified of changes."
              type="warning"
              {...alertCommonProps}
            />
          )}

          {editable && isManagedBySubBase && (
            <Alert
              message={
                <Typography.Text>
                  This material is part of SubBase&apos;s catalog, which is why you are unable to edit all the fields.
                  If anything looks incorrect, feel free to email us at{' '}
                  <Typography.Link href={`mailto: ${SUPPORT_EMAIL_ADDRESS}`}>{SUPPORT_EMAIL_ADDRESS}</Typography.Link>
                </Typography.Text>
              }
              type="info"
              {...alertCommonProps}
              style={{ backgroundColor: '#e6f7ff', borderColor: '#91d5ff', ...alertCommonProps.style }}
            />
          )}

          {editable && userStore.canEditMaterialDatabase && (
            <Form.Item style={{ margin: 0, width: '100%' }}>
              <Divider />
              <FlexBoxX width="100%" justifyContent="space-between" paddingBottom={16}>
                <Button onClick={handleCancel} style={{ marginRight: 16, width: 100 }} loading={isSubmitting}>
                  Cancel
                </Button>

                <Space size="middle">
                  {onDuplicate && (
                    <Button onClick={() => onDuplicate(companyMaterial)} style={{ width: 100 }} loading={isSubmitting}>
                      Duplicate
                    </Button>
                  )}

                  {scope === 'MaterialPage' ? (
                    <Button type="primary" htmlType="submit" style={{ width: 100 }} loading={isSubmitting}>
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      data-cy="material-save-button"
                      onClick={() => {
                        if (companyMaterial?.id && previouslyOrdered && !isRequestedMaterial) {
                          setShowModalUpdateOrCreate(true)
                        } else {
                          form.submit()
                        }
                      }}
                      style={{ width: 100 }}
                      loading={isSubmitting}
                    >
                      {isRequestedMaterial ? 'Approve' : 'Save'}
                    </Button>
                  )}
                </Space>
              </FlexBoxX>
            </Form.Item>
          )}
        </FlexBoxY>
      </FlexBoxY>
    </Form>
  )
})
