import React, { useRef } from 'react'

import { uniqBy } from 'lodash'

import { DeleteOutlined, PlusOutlined, ShopOutlined, EditOutlined } from '@ant-design/icons'
import { Typography, Tooltip, Button, Divider } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box, FlexBoxX } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { Select } from 'common/components/Select'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'

type VendorContactsDrawerProps = {
  disabled?: boolean
}

const makeOption = ({ contact }) => {
  const { id, first_name, last_name, email, nickname } = contact
  const name = formatName(first_name, last_name)
  const label = `${name || email}${nickname ? ` (${nickname})` : ''}`
  return { label, value: id, contact }
}

export const VendorContactsDrawer = observer((props: VendorContactsDrawerProps) => {
  const { disabled } = props
  const { orderStore, companyVendorStore, userStore } = useStores()

  const theme = useTheme()

  const drawerCreateRef = useRef<DrawerRef>()
  const drawerRef = useRef<DrawerRef>()

  const allContactsByVendor = companyVendorStore.companyVendorContacts

  const { company_vendor, vendor_contacts = [] } = orderStore.selectedOrder

  const { isLoading } = useQuery(
    () => companyVendorStore.getCompanyVendorContactsByCompanyVendorId(company_vendor?.id),
    [company_vendor?.id],
  )

  const safeGlobalVendorName = company_vendor?.vendor?.name || company_vendor?.vendor_name

  const handleChangeContacts = (value = []) => {
    const selectedVendorContactIds = value?.map((contact) => contact?.value)
    const newContacts =
      value?.map(({ contact }) => ({
        id: contact?.id,
        email: contact?.email,
        first_name: contact?.first_name,
        last_name: contact?.last_name,
        nickname: contact?.nickname,
      })) || []

    orderStore.updateSelectedOrder('vendor_contact_ids', selectedVendorContactIds)
    orderStore.updateSelectedOrder('vendor_contacts', uniqBy([...vendor_contacts, ...newContacts], 'id'))
  }

  const handleFinishCreateVendor = (newVendorContact) => {
    companyVendorStore.getCompanyVendorContactsByCompanyVendorId(company_vendor?.id)

    orderStore.updateSelectedOrder('vendor_contact_ids', [
      ...vendor_contacts.map((contact) => contact?.id),
      newVendorContact?.contact?.id,
    ])
    orderStore.updateSelectedOrder('vendor_contacts', [
      ...vendor_contacts,
      {
        id: newVendorContact?.contact?.id,
        email: newVendorContact?.contact?.email,
        first_name: newVendorContact?.contact?.first_name,
        last_name: newVendorContact?.contact?.last_name,
        nickname: newVendorContact?.contact?.nickname,
      },
    ])
  }

  const handleRemoveContact = (id: string) => {
    const filteredVendorContacts = vendor_contacts.filter((contact) => contact.id !== id)

    orderStore.updateSelectedOrder(
      'vendor_contact_ids',
      filteredVendorContacts.map((contact) => contact.id),
    )
    orderStore.updateSelectedOrder('vendor_contacts', filteredVendorContacts)
  }

  const isMinVendorContact = vendor_contacts?.length <= 1

  return (
    <>
      <Tooltip title="Vendor" placement="bottom">
        <Box
          display="inline-flex"
          alignItems="center"
          onClick={() => {
            if (disabled) return
            drawerRef.current?.show()
          }}
          py={2}
          width="100%"
          height="30px"
          justifyContent="flex-start"
          style={{ cursor: 'pointer' }}
        >
          <ShopOutlined />
          <Typography.Text
            style={{ margin: '0 4px', fontSize: 12, color: theme.colors['gray-8'] }}
            ellipsis={{ tooltip: safeGlobalVendorName }}
          >
            {safeGlobalVendorName}
          </Typography.Text>
          {!disabled && <EditOutlined style={{ color: theme.colors.link }} />}
        </Box>
      </Tooltip>

      <Drawer title="Vendor Contacts" ref={drawerRef} forceRender width={378}>
        <FlexBoxY alignItems="flex-start" justifyContent="flex-start" p={16}>
          <Box display="flex" alignItems="center" width="100%" mb={16}>
            <Box width="100%">
              <Select
                placeholder="Search for contact to add"
                noOptionsMessage={() => 'No contacts'}
                components={{ ClearIndicator: null, IndicatorSeparator: null }}
                blurInputOnSelect={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect
                hideSelectedOptions
                isMulti
                value={vendor_contacts?.map((contact) => makeOption({ contact }))}
                options={allContactsByVendor?.map(makeOption)}
                onChange={handleChangeContacts}
                isLoading={isLoading}
              />
            </Box>

            {userStore.canManageVendors && (
              <>
                <Tooltip title="Add new vendor contact" placement="topRight">
                  <Button
                    icon={<PlusOutlined />}
                    style={{ marginLeft: 12 }}
                    onClick={() => drawerCreateRef.current.show()}
                  />
                </Tooltip>
                <CreateCompanyVendorContact
                  selectedCompanyVendorId={company_vendor?.id}
                  onFinish={handleFinishCreateVendor}
                  onCancel={() => drawerCreateRef.current.close()}
                  hideShowSaveAndAddAnotherButton
                  ref={drawerCreateRef}
                />
              </>
            )}
          </Box>

          {vendor_contacts?.map((contact, index) => {
            const { id, first_name, last_name, email, vendor_user, nickname } = contact

            const safeGlobalVendorContactName = vendor_user
              ? formatName(vendor_user?.first_name, vendor_user?.last_name)
              : formatName(first_name, last_name)

            return (
              <React.Fragment key={id}>
                <FlexBoxX justifyContent="space-between" width="100%" flexGrow={0}>
                  <FlexBoxY alignItems="baseline" width="calc(100% - 34px)">
                    <Typography.Text ellipsis style={{ width: '100%' }}>
                      {`${safeGlobalVendorContactName}${nickname ? ` (${nickname})` : ''}`}
                    </Typography.Text>
                    <Typography.Text type="secondary" ellipsis style={{ width: '100%' }}>
                      {vendor_user?.email || email}
                    </Typography.Text>
                  </FlexBoxY>

                  <Tooltip
                    placement="topRight"
                    title={
                      isMinVendorContact
                        ? "You can't remove the last contact, there needs to be at least one!"
                        : 'Remove'
                    }
                  >
                    <Button
                      style={{ marginLeft: '12px' }}
                      size="small"
                      type="dashed"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveContact(id)}
                      disabled={isMinVendorContact}
                    />
                  </Tooltip>
                </FlexBoxX>
                {index !== vendor_contacts?.length - 1 && <Divider style={{ margin: '4px 0' }} />}
              </React.Fragment>
            )
          })}
        </FlexBoxY>
      </Drawer>
    </>
  )
})
