import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { omit } from 'lodash'

import { Alert, Form, Input, Row, Col, Button, Divider } from 'antd'
import { FormProps } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { makeCostCodeValue } from 'common/components/SelectCostCode'
import { makeOption as makeUnitOption } from 'common/components/SelectUnit'
import Tags from 'common/components/tags'
import { formatEnumValue } from 'common/helpers/formatters'
import { Unit } from 'common/server/units'

import { useNotifyCompanyMaterial } from 'contractor/hooks/use-notify-company-material'
import { useStores } from 'contractor/hooks/use-stores'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { FieldCompanyVendors } from './Fields/field_company_vendors'
import { FieldCostCode } from './Fields/field_cost_code'
import { FieldCostCodePhase } from './Fields/field_cost_code_phase'
import { FieldDescription } from './Fields/field_description'
import { FieldImageUrl } from './Fields/field_image_url'
import { FieldProjectEspecific } from './Fields/field_project_especific'
import { FieldUnit } from './Fields/field_unit'
import { getErrorMessages } from './utils'

type CreateMaterialProps = {
  title?: string
  onCancel: () => void
  onSubmit?: (response: ShowCompanyMaterialResponse) => void
  initialValues?: FormProps['initialValues']
  hideNotification?: boolean
  publicOrderFormUrlExtension?: string
  companyAttributes?: string[]
  units?: Unit[]
  costCodeSettings?: {
    required: boolean
    project_filtering_enabled: boolean
    class_enabled: boolean
    phase_code_enabled: boolean
    independent_phase_codes_enabled: boolean
  }
  requestedById?: string
}

export const getFormUnit = (formValues) => {
  if (formValues?.unit?.value === formValues?.unit?.label) {
    return { unit_name: formValues?.unit?.label, unit_id: null }
  }

  if (formValues?.unit?.value) {
    return { unit_id: formValues?.unit?.value, unit_name: null }
  }

  return { unit_id: null, unit_name: null }
}

export const CreateMaterial = observer<CreateMaterialProps, DrawerRef>(
  (props, ref) => {
    const {
      title = 'Add New Material',
      onSubmit,
      onCancel,
      initialValues,
      hideNotification,
      publicOrderFormUrlExtension,
      companyAttributes: companyAttributesProps,
      units,
      costCodeSettings,
      requestedById,
    } = props

    const location = useLocation()
    const source = location.pathname.substr(1)
    const { companyMaterialStore, companySettingStore, userStore } = useStores()

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const { notifyCompanyVendor } = useNotifyCompanyMaterial()

    const [form] = Form.useForm()
    const imageUrlField = Form.useWatch('image_url', form)

    const createMaterial = (companyMaterial) => {
      if (publicOrderFormUrlExtension) {
        return companyMaterialStore.publicCreate(companyMaterial, publicOrderFormUrlExtension)
      }

      return companyMaterialStore.create(companyMaterial)
    }

    const handleFinish = async (formValues) => {
      const companyMaterial = omit(formValues, ['cost_code'])
      companyMaterial.created_from = 'COMPANY_MATERIAL_ADD_MODAL'
      setSubmitting(true)

      try {
        const response = await createMaterial({
          ...companyMaterial,
          cost_code_id: formValues?.cost_code?.value,
          cost_code_phase_id: formValues?.cost_code_phase?.value,
          requested_by_id: requestedById,
          ...getFormUnit(formValues),
        })
        form.resetFields()
        setAlertMessage('')
        onSubmit?.(response)
        if (!hideNotification) {
          notifyCompanyVendor({ companyMaterialId: response?.id })
        }
      } catch (error) {
        const errorMessage = getErrorMessages(error)
        setAlertMessage(errorMessage)
      } finally {
        setSubmitting(false)
        trackEvent(Events.ADD_MATERIAL, source)
      }
    }

    const handleCancel = () => {
      setAlertMessage('')
      form.resetFields()
      onCancel()
    }

    useEffect(() => {
      const getInitialUnit = () => {
        if (initialValues?.unit_id) {
          return makeUnitOption(initialValues?.unit)
        }

        if (initialValues?.unit_name) {
          return { value: initialValues?.unit_name, label: initialValues?.unit_name }
        }
      }

      form.setFieldsValue({
        ...initialValues,
        cost_code: makeCostCodeValue(initialValues?.cost_code, costCodeSettings),
        unit: getInitialUnit(),
      })
    }, [initialValues])

    const companyAttributes = React.useMemo(() => {
      const columns =
        companyAttributesProps || companySettingStore.companyMaterialConfiguration.company_attributes || []

      const projectIdsIndex = columns.indexOf('project_ids')
      const preferredVendorPricesIndex = columns.indexOf('preferred_vendor_prices')

      let newColumns = [...columns]

      if (projectIdsIndex !== -1) {
        newColumns = newColumns.filter((item) => item !== 'project_ids')
        newColumns.push('project_ids')
      }

      if (preferredVendorPricesIndex !== -1) {
        newColumns = newColumns.filter((item) => item !== 'preferred_vendor_prices')
        newColumns.push('preferred_vendor_prices')
      }

      return newColumns
    }, [companyAttributesProps, companySettingStore.companyMaterialConfiguration.company_attributes?.length])

    return (
      <Drawer aria-label="create-material-drawer" ref={ref} bgGray title={title} onClose={handleCancel}>
        <Box width="100%" p={16} overflow="auto">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

          <Form layout="vertical" form={form} style={{ width: '100%' }} onFinish={handleFinish}>
            <Row gutter={20}>
              {companyAttributes.map((columnName) => {
                switch (columnName) {
                  case 'cost_code_id':
                    return (
                      <React.Fragment key={columnName}>
                        <Col xs={24} sm={12}>
                          <FieldCostCode
                            data-cy="material-cost-code-input"
                            inputProps={{
                              isCreate: !publicOrderFormUrlExtension,
                              disabled: !userStore.canCreateNewMaterial,
                            }}
                          />
                        </Col>
                        {companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled && (
                          <Col xs={24} sm={12}>
                            <FieldCostCodePhase
                              form={form}
                              inputProps={{
                                disabled: !userStore.canCreateNewMaterial,
                              }}
                            />
                          </Col>
                        )}
                      </React.Fragment>
                    )
                  case 'preferred_vendor_prices':
                    return (
                      <Col span={24} key={columnName}>
                        <FieldCompanyVendors data-cy="material-vendor-input" parentForm={form} />
                      </Col>
                    )
                  case 'unit':
                    return (
                      <Col xs={24} sm={12} key={columnName}>
                        <FieldUnit data-cy="material-unit-input" units={units} />
                      </Col>
                    )
                  case 'description':
                    return (
                      <Col xs={24} key={columnName}>
                        <FieldDescription data-cy="material-description-input" />
                      </Col>
                    )
                  case 'project_ids':
                    return (
                      <Col xs={24} key={columnName}>
                        <FieldProjectEspecific
                          data-cy="material-projects-input"
                          inputProps={{
                            disabled: !userStore.canCreateNewMaterial,
                          }}
                        />
                      </Col>
                    )
                  default:
                    return (
                      <Col xs={24} sm={12} key={columnName}>
                        <Form.Item name={[columnName]} label={formatEnumValue(columnName)}>
                          <Input
                            data-cy={`material-${columnName}-input`}
                            style={{ width: '100%' }}
                            disabled={!userStore.canCreateNewMaterial}
                          />
                        </Form.Item>
                      </Col>
                    )
                }
              })}

              <Col span={24}>
                <Form.Item name="tags" label="Tags">
                  <Tags tags={companyMaterialStore.companyMaterialTags} />
                </Form.Item>
              </Col>

              <FieldImageUrl imageUrlField={imageUrlField} />
            </Row>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleCancel} loading={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button
                data-cy="material-save-button"
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                style={{ width: 100 }}
              >
                Create
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
