import React from 'react'

import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import { usePushToOrder } from 'common/hooks/use-push-to-order'

import { Cell } from 'contractor/pages/@v2/Commitments/Components/CommitmentListCells'
import { Commitment } from 'contractor/server/commitments/commitment'

interface Props {
  disableProjectName: boolean
  getNotificationsCount: (id: string) => number
  tagsColumn: {
    disabled: boolean
    onChange: (tags: string[], orderId: string) => Promise<void>
  }
}

export function columnsFactory(props: Props): Array<PsqlColumn<Commitment>> {
  const { disableProjectName, tagsColumn, getNotificationsCount } = props
  const { getCommitmentUrl } = usePushToOrder()

  return [
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
      width: 176,
      sortBy: 'status',
      Cell: ({ row }) => (
        <Cell.Status
          status={row.original.status}
          isExpired={row.original.is_expired}
          notificationCount={getNotificationsCount(row.original.commitment_id)}
        />
      ),
    },
    {
      Header: 'Project',
      id: 'project_name',
      disabled: disableProjectName,
      width: 200,
      Cell: ({ row, column }) => (
        <Cell.FormattedText
          column={column}
          value={`${row.original.project_number ? `${row.original.project_number}: ` : ''}${row.original.project_name}`}
        />
      ),
    },
    {
      Header: 'Commitment',
      id: 'commitment_name',
      accessor: 'commitment_name',
      sortBy: 'commitment',
      width: 400,
      Cell: ({ row }) => (
        <Cell.CellCommitmentNumber
          name={row.original.commitment_name}
          number={row.original.commitment_number}
          href={getCommitmentUrl(row.original.commitment_id)}
        />
      ),
    },
    {
      Header: 'Vendor',
      id: 'vendor_name',
      accessor: 'vendor_name',
      sortBy: 'vendor',
      width: 250,
      Cell: ({ row }) => <Cell.VendorName vendorName={row?.original?.vendor_name} />,
    },
    {
      Header: 'Expiration Date',
      id: 'expiration_date',
      accessor: 'expiration_date',
      width: 160,
      Cell: ({ value }) => <Cell.ExpirationDate date={value} />,
    },
    {
      Header: 'Created at',
      id: 'created_at',
      accessor: 'created_at',
      width: 160,
      sortBy: 'created',
      format: 'date',
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Total ordered',
      id: 'total_ordered',
      accessor: 'total_ordered',
      width: 120,
      format: 'currency',
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Total invoiced',
      id: 'total_invoiced',
      accessor: 'total_invoiced',
      width: 120,
      format: 'currency',
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Tags',
      id: 'tags',
      accessor: 'tags',
      disabled: tagsColumn.disabled,
      Cell: ({ row, value }) => (
        <Cell.Tags
          tags={row.original.tags}
          orderId={row.original.commitment_id}
          value={value}
          onChange={(tags) => tagsColumn.onChange(tags, row.original.commitment_id)}
        />
      ),
    },
    {
      Header: 'Total un-invoiced',
      id: 'total_uninvoiced',
      accessor: 'total_uninvoiced',
      width: 160,
      format: 'currency',
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Submitted At',
      id: 'commitment_submitted_at',
      accessor: 'commitment_submitted_at',
      width: 160,
      format: 'date',
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Submitted by',
      id: 'submitted_by',
      accessor: 'submitted_by',
      width: 160,
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
    {
      Header: 'Drafted By',
      id: 'drafted_by',
      accessor: 'drafted_by',
      width: 250,
      Cell: ({ value, column }) => <Cell.FormattedText value={value} column={column} />,
    },
  ]
}
