import { initializeAmplitude, AmplitudeUser } from 'common/analytics/amplitude/initialize_amplitude'
import { HeapUser, initializeHeap } from 'common/analytics/heap/initialize_heap'
import { initializePendo, PendoUser } from 'common/analytics/pendo/initialize_pendo'

export default abstract class UserBaseStore {
  maybeInitializePendo(user: PendoUser) {
    initializePendo(user)
  }

  maybeInitializeAmplitude(user: AmplitudeUser) {
    initializeAmplitude(user)
  }

  maybeInitializeHeap(user: HeapUser) {
    initializeHeap(user)
  }
}
