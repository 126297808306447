import React from 'react'

import moment from 'moment/moment'

import styled from '@emotion/styled'

import { Table as AntdTable } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { OrdersList } from 'common/components/OrdersList'
import { currencyFormatter } from 'common/helpers/formatters'
import { usePushToOrder } from 'common/hooks/use-push-to-order'

import { Wrapper } from 'contractor/pages/@v2/Commitments/Components/CommitmentTable/Table/wrapper'
import { Cell } from 'contractor/pages/@v2/Orders/components/cells'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'

const insertIf = (condition, ...elements) => (condition ? elements : [])

const StyledTable = styled(AntdTable)`
  .ant-table.cell {
    background-color: white !important;
  }

  .ant-table.table {
    background-color: white !important;
  }
`

type TableProps = {
  dataSource: ConsolidatedOrdersMaterials.OrderMaterial[]
  isOrder: boolean
}

export const Table = observer<TableProps>((props) => {
  const { dataSource, isOrder } = props
  const { getUrl } = usePushToOrder()

  return (
    <FlexBoxY bg="white" mt="16" width="100%">
      <Wrapper alignItems="flex-start" justifyContent="flex-start" width="100%" overflowX="auto">
        <StyledTable
          rowKey={(record) => record?.order_material_id}
          pagination={false}
          size="small"
          style={{ width: '100%', backgroundColor: 'white' }}
          data-cy="material-table"
          aria-label="order-materials-table"
          dataSource={dataSource}
          columns={[
            {
              title: 'PO',
              width: '20%',
              minWidth: 120,
              render: (record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                const pathToOpenOrder = getUrl({
                  orderId: record.order_id,
                  orderPackageId: record.order_package_id,
                  state: record.order_state,
                  subState: record.order_sub_state,
                })

                return (
                  <Cell.OrderNumber
                    id={record.order_id}
                    name={record.order_name}
                    number={record.order_number}
                    href={pathToOpenOrder}
                  />
                )
              },
            },
            {
              title: 'Status',
              dataIndex: 'state',
              width: '8%',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return (
                  <OrdersList.Cell.Status
                    state={record.order_state}
                    subState={record.order_sub_state}
                    hasOpenDeliveryIssue={record.delivery_has_open_issue}
                    deliveries={[]}
                    notificationsCount={0}
                  />
                )
              },
            },
            ...insertIf(isOrder, {
              title: 'Description',
              width: '20%',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return record.company_material_description
              },
            }),
            {
              width: '10%',
              title: 'Qty ordered',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return record.quantity
              },
            },
            {
              width: '10%',
              title: 'Unit',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return record.company_material_unit_name || record.company_material_unit_name_with_increment_label
              },
            },
            {
              width: '10%',
              title: 'Ext Cost',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return currencyFormatter(record.ext_cost, 2)
              },
            },
            {
              width: '10%',
              title: 'Ordered at',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return (record.order_ordered_at && moment(record.order_ordered_at).format('ddd. MMM Do, YYYY')) ?? '-'
              },
            },
            {
              width: '10%',
              title: 'Ordered by',
              minWidth: 120,
              render: (_, record: ConsolidatedOrdersMaterials.OrderMaterial) => {
                return record.order_ordered_by
              },
            },
          ]}
        />
      </Wrapper>
    </FlexBoxY>
  )
})
