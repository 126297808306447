import React from 'react'

import { ShopOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { CustomEditableContent } from 'common/components/CustomEditableContent'

import { SelectCompanyVendor } from 'contractor/components/SelectCompanyVendor'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../@v2/context'

interface CompanyVendorSelectorProps {
  disabled?: boolean
}

const CompanyVendorNameLabel = ({ companyVendorName }) => (
  <Tooltip title="Vendor" placement="bottom">
    <Space>
      <ShopOutlined />
      <Typography.Text type="secondary">{companyVendorName || 'Unknown Seller'}</Typography.Text>
    </Space>
  </Tooltip>
)

export const CompanyVendorSelector = observer((props: CompanyVendorSelectorProps) => {
  const { invoiceStore } = useStores()

  const { setSelectedInvoiceDirty } = useInvoice()

  const invoice = invoiceStore.invoice

  return (
    <CustomEditableContent
      boxProps={{ mt: 0 }}
      disabled={props?.disabled}
      input={
        <SelectCompanyVendor
          value={invoice.company_vendor_id}
          disabled={props?.disabled}
          onChange={(companyVendorId, option) => {
            invoiceStore.updateSelectedInvoice('company_vendor_id', companyVendorId)
            invoiceStore.updateSelectedInvoice('company_vendor', option?.['original'] || null)
            setSelectedInvoiceDirty?.(true)
          }}
          isCreate
          showSearch
          allowClear
          style={{ width: '100%', minWidth: 180 }}
        />
      }
    >
      <CompanyVendorNameLabel
        companyVendorName={invoice.company_vendor?.vendor?.name || invoice.company_vendor?.vendor_name}
      />
    </CustomEditableContent>
  )
})
