import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { OrderMaterialTabs } from 'common/components/@v2/OrderMaterialTabs'
import { Box } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'
import { Address } from 'common/server/addresses'
import { emptyOrderDelivery } from 'common/server/deliveries'

import { DeliveryInfo } from 'contractor/components/OrderDeliveries'
import { getDeliveryPickUpTitle } from 'contractor/helpers/get-delivery-pick-up-title'
import { useStores } from 'contractor/hooks/use-stores'
import { useNewQuote } from 'contractor/pages/NewQuote/context'
import { addCompanyVendor } from 'contractor/pages/NewQuote/VendorForm'

import { MaterialsSpreadsheet } from './materials_spreadsheet'
import { MaterialsV2 } from './materials_v2'

export type MaterialsAndDeliveriesProps = {
  orderType: OrderType
  disabled?: boolean
  isPoLocked?: boolean
  overBudgetMaterial?: string
}

export const MaterialsAndDeliveries = observer<MaterialsAndDeliveriesProps>(
  ({ orderType, disabled, isPoLocked, overBudgetMaterial }) => {
    const { orderStore, companyVendorStore, companySettingStore } = useStores()

    const { setCompanyVendors, companyVendors, projectId, isSpreadsheetMode } = useNewQuote()

    const deliveries = orderStore.newDeliveries

    const handleAddDelivery = () => {
      const newDelivery = emptyOrderDelivery()
      deliveries.push(newDelivery)
      orderStore.addEmptyOrderMaterials(newDelivery.id, 5)
      orderStore.setOrderDirty()
    }

    const handleRemoveDelivery = (index: number) => {
      Modal.confirm({
        title: 'Are you sure you want to remove this delivery?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deliveries.splice(index, 1)
          orderStore.setOrderDirty()
          orderStore.calcTotalCost({
            deliveries,
            precision: companySettingStore.otherSettings?.rounding_precision_settings?.order_precision,
          })
        },
      })
    }

    const handleChangeAddress = (address: Nullable<Address>) => {
      if (address?.owned_by_type === 'CompanyVendor') {
        const vendorContact = companyVendorStore.vendorContacts.find(
          (vendorContact) => vendorContact.company_vendor.id === address.owned_by_id,
        )
        const newCompanyVendors = addCompanyVendor(vendorContact, '', [])

        setCompanyVendors(newCompanyVendors)
        orderStore.setOrderDirty()
      }
    }

    let deliveryCount = 0
    let pickUpCount = 0

    const companyVendorId = companyVendors[0]?.id

    return (
      <OrderMaterialTabs
        onDuplicate={(deliveryIndex) => orderStore.duplicateDelivery(deliveryIndex, deliveries)}
        onAdd={handleAddDelivery}
        deliveriesCount={deliveries.length}
        hideAdd={orderStore.isSplitting}
        disabled={disabled}
        tagsProps={{
          tags: orderStore.orderTags,
          hidden: true,
        }}
        items={deliveries?.map((delivery, index) => {
          const idx = index + 1
          delivery.is_pick_up ? pickUpCount++ : deliveryCount++

          const deliveryName = getDeliveryPickUpTitle({
            isPickUp: delivery.is_pick_up,
            pickUpCount,
            deliveryCount,
          })

          return {
            title: deliveryName,
            label: (
              <OrderMaterialTabs.TabPaneTitle
                title={deliveryName}
                isPickUp={delivery.is_pick_up}
                delivery={delivery}
                isRemovable={deliveries.length > 1}
                handleRemove={() => handleRemoveDelivery(index)}
              />
            ),
            key: `${index}`,
            closable: deliveries.length > 1 && !orderStore.isSplitting,
            children: (
              <>
                <DeliveryInfo
                  project_ids={[projectId]}
                  delivery={delivery}
                  showDetails={false}
                  orderType={orderType}
                  disabled={disabled}
                  companyVendorId={companyVendorId}
                  onChangeAddress={handleChangeAddress}
                />
                {isSpreadsheetMode ? (
                  <MaterialsSpreadsheet
                    index={idx}
                    deliveryId={delivery.id}
                    deliveryName={deliveryName}
                    orderType={orderType}
                    isPoLocked={isPoLocked}
                  />
                ) : (
                  <MaterialsV2
                    index={idx}
                    deliveryId={delivery.id}
                    orderType={orderType}
                    disabled={disabled}
                    overBudgetMaterial={overBudgetMaterial}
                    isPoLocked={isPoLocked}
                    deliveryName={deliveryName}
                  />
                )}
                <Visibility.Hidden>
                  <Box mt={24} />
                </Visibility.Hidden>
              </>
            ),
          }
        })}
      />
    )
  },
)
